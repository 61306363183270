import sprintf from "sprintf-js";

//From multi dimention array make one dimention array
//that is the sum of there elements
// for example [[1,1,1],[2,2,2,2,2,2]]
// will be [5,5,5]
export const oneDimVol = (array, mult, hours) => {
  if (!array.length) return [];
  //Make all sub arrays the same length
  const sameDimArrays = array.map((el, ind) => {
    const rez = [];
    const arrLength = el.length ? el.length * mult[ind] : hours;
    for (let i = 0; i < arrLength; i++) {
      let sum = 0;
      for (let j = 0; j < 1 / mult[ind]; j++) {
        const value = el?.[i * (1 / mult[ind]) + j]
        sum += value === undefined ? 0 : value;
      }
      rez.push(sum);
    }
    return rez;
  });

  //Sum all sub arrays in one
  const result = sameDimArrays[0].map((_, index) =>
    sameDimArrays.reduce((acc, subArray) => acc + subArray[index], 0)
  );
  return result;
};

export const oneDimVolGran = (array, mult, dtShouldBe, hours) => {
  if (!array.length) return [];
  //Make all sub arrays the same length
  const sameDimArrays = array.map((el, ind) => {
    const rez = [];
    if(mult[ind] < dtShouldBe) {
      const itemsPerHour = dtShouldBe / mult[ind];
      const arrLength = el.length ? el.length / itemsPerHour : hours / dtShouldBe;
      for (let i = 0; i < arrLength; i++) {
        let sum = 0;
        for (let j = 0; j < itemsPerHour; j++) {
          const value = el?.[i * (itemsPerHour) + j]
          sum += value === undefined ? 0 : value;
        }
        rez.push(sum);
      }
    } else if(mult[ind] === dtShouldBe) {
      if(el.length)
        return el;
      else 
        return Array(hours / Number(dtShouldBe)).fill(0)
    } else if (mult[ind] > dtShouldBe) {
      const itemsPerHour = mult[ind] / dtShouldBe;
      for (let i = 0; i < el.length; i++) {
        for (let j = 0; j < itemsPerHour; j++) {
          rez.push(el[i] ? el[i]/itemsPerHour : 0)
        }
      }
    }
    return rez;
  });

  //Sum all sub arrays   in one
  const result = sameDimArrays[0].map((_, index) =>
    sameDimArrays.reduce((acc, subArray) => acc + subArray[index], 0)
  );
  return result;
};

// from this [[1,1,1],[2,2,2,2,2,2]] to this [1,1,1,2,2,2,2,2,2]
export const oneDimArr = (array) => {
  if (!array.length) return [];
  const result = array.reduce((acc, subArray) => acc.concat(subArray), []);

  return result;
};

export const resampleArrToHoursDim = (array, mult) => {
  if (!array.length) return [];
  const result = [];
  for (let i = 0; i < array.length * mult; i++) {
    let sum = 0;
    for (let j = 0; j < 1 / mult; j++) {
      sum += array[i * (1 / mult) + j];
    }
    result.push(sum);
  }
  return result;
};

export const resampleArrToGran = (array, mult, dtShouldBe) => {
  if (!array.length) return [];
  const result = [];
  if(mult < dtShouldBe) {
    const itemsPerHour = dtShouldBe / mult;
    for (let i = 0; i < array.length * mult / dtShouldBe; i++) {
      let sum = 0;
      for (let j = 0; j < itemsPerHour; j++) {
        sum += array[i * itemsPerHour + j];
      }
      result.push(sum);
    }
  } else if (mult === dtShouldBe) {
    return array;
  } else if(mult > dtShouldBe) {
    const itemsPerHour = mult / dtShouldBe;
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < itemsPerHour; j++) {
        result.push(array[i]/itemsPerHour);
      }
      
    }
  }
  return result;
};

export const resampleIdTrades = (array, mult) => {
  const id_trades = [];
  for (var s = 0; s < mult.length; s++) {
    const stage_trades = [...array[s]];
    for (var t = 0; t < stage_trades.length; t++) {
      id_trades.push(stage_trades[t]);
    }
  }
  return id_trades;
};

//First make all arrays the same length
//calculate average price for each hour
//for da_vol:[[30,30,....],[10,10,...]] and da_prices: [[100,90,...],[90,80,...]] 
//will have for 00:00 - (100*30+90*10)/40=97.5
export const oneDimDaPriceSam = (vol, price, mult) => {
  if (!vol.length || !price.length) return [];
  //Make all arrays the same length
  const sameDimVol = [];
  const sameDimPrice = [];
  vol.forEach((el, ind) => {
    const rezPrice = [];
    const rezVol = [];
    if(el.length === 0) return;
    for (let i = 0; i < el.length * mult[ind]; i++) {
      let sumVol = 0;
      let sumCashFlow = 0;
      let sumPrice = 0;
      for (let j = 0; j < 1 / mult[ind]; j++) {
        sumVol += el[i * (1 / mult[ind]) + j];
        sumCashFlow +=
          el[i * (1 / mult[ind]) + j] * price[ind][i * (1 / mult[ind]) + j];
        sumPrice += price[ind][i * (1 / mult[ind]) + j];
      }
      rezVol.push(sumVol);
      // if sumVol is 0 then calculate average price for the hour
      rezPrice.push(sumVol ? sumCashFlow / sumVol : sumPrice / (1 / mult[ind]));
    }
    sameDimVol.push(rezVol);
    sameDimPrice.push(rezPrice);
  });

  const avgPrice = [];
  sameDimPrice.length && sameDimPrice[0].forEach((el, ind) => {
    let sumVol = 0;
    let sumCashFlow = 0;
    let sumPrice = 0;
    for (let i = 0; i < sameDimVol.length; i++) {
      sumCashFlow += sameDimVol[i][ind] * sameDimPrice[i][ind];
      sumVol += sameDimVol[i][ind];
      sumPrice += sameDimPrice[i][ind];
    }
    // if sumVol is 0 then calculate average price for the auctions
    avgPrice.push(sumVol ? sumCashFlow / sumVol : sumPrice / sameDimVol.length);
  });
  return avgPrice;
};

// Create price array in needed granularity
export const oneDimDaPriceSamGran = (vol, price, mult, dtShouldBe, hours) => {
  if (!vol.length || !price.length) return [];
  //Make all arrays the same length
  const sameDimVol = [];
  const sameDimPrice = [];
  vol.forEach((el, ind) => {
    if(el.length === 0) return;
    const rezPrice = [];
    const rezVol = [];
    if(mult[ind] < dtShouldBe) {
      const itemsPerHour = dtShouldBe / mult[ind];
      const arrLength = el.length ? el.length / itemsPerHour : hours / dtShouldBe;
      for (let i = 0; i < arrLength; i++) {
        let sumVol = 0;
        let sumCashFlow = 0;
        let sumPrice = 0;
        for (let j = 0; j < itemsPerHour; j++) {
          sumVol += el[i * itemsPerHour + j];
          sumCashFlow +=
            el[i * itemsPerHour + j] * price[ind][i * itemsPerHour + j];
          sumPrice += price[ind][i * itemsPerHour + j];
        }
        rezVol.push(sumVol);
        // if sumVol is 0 then calculate average price for the hour
        rezPrice.push(sumVol ? sumCashFlow / sumVol : sumPrice / itemsPerHour);
      }
      sameDimVol.push(rezVol);
      sameDimPrice.push(rezPrice);
    } else if(mult[ind] === dtShouldBe) {
      sameDimVol.push(vol[ind]);
      sameDimPrice.push(price[ind]);
    } else if(mult[ind] > dtShouldBe) {
      const itemsPerHour = mult[ind] / dtShouldBe;
      for (let i = 0; i < el.length; i++) {
        for (let j = 0; j < itemsPerHour; j++) {
          rezVol.push(el[i]/itemsPerHour);
          rezPrice.push(price[ind][i])
        }
      }
      sameDimVol.push(rezVol);
      sameDimPrice.push(rezPrice);
    }
  });
  
  const avgPrice = [];
  sameDimPrice.length && sameDimPrice[0].forEach((el, ind) => {
    let sumVol = 0;
    let sumCashFlow = 0;
    let sumPrice = 0;
    for (let i = 0; i < sameDimVol.length; i++) {
      sumCashFlow += sameDimVol[i][ind] * sameDimPrice[i][ind];
      sumVol += sameDimVol[i][ind];
      sumPrice += sameDimPrice[i][ind];
    }
    // if sumVol is 0 then calculate average price for the auctions
    avgPrice.push(sumVol ? sumCashFlow / sumVol : sumPrice / sameDimVol.length);
  });
  return avgPrice;
};

export const calcGranData = (data, dtBase, dtShouldBe, par) => {
  let hourData = [];
  if(dtBase < dtShouldBe) {
    const itemsPerHour = dtShouldBe / dtBase;
    for (let i = 0; i < data.length; i = i + itemsPerHour) {
      let sumValue = 0;
      for (let j = 0; j < itemsPerHour; j++) {
        sumValue += data[i + j];
      }
      if(par === 'vol') {
        hourData.push(Number(sumValue.toFixed(2)));
      }
      else if (par === 'price')
        hourData.push(Number((sumValue * dtBase).toFixed(2)));
    }
  }
  else if(dtBase > dtShouldBe) {
    const itemsPerHour = dtBase / dtShouldBe;
    for (let i = 0; i < data.length * itemsPerHour; i = i + itemsPerHour) {
      const value = data[i / itemsPerHour];
      for (let j = 0; j < itemsPerHour; j++) {
        if(par === 'vol') {
          hourData.push(Number((value/itemsPerHour).toFixed(2)));
        }
        else if (par === 'price')
          hourData.push(value);
      }
    }
  } else {
    return data;
  }

  return hourData;
};

const remove_leading_zeros = (s) => {
  return parseInt(s, 10).toString(10);
}

const add_leading_zeros = (s) => {
  return sprintf.sprintf('%02d', parseInt(s, 10));
}

const transform = (time, convertFunc) => {
  return time.split(',').map(t => {
    if (t) {
      if (t.includes('-')) {
        return t.split('-').map(el => {
          if (el) {
            return el.includes('/') 
              ? el.split('/').map(item => item ? convertFunc(item) : '0').join('/') 
              : convertFunc(el);
          }

          return '0';
        }).join('-');
      }

      return convertFunc(t);
    }

    return '0';
  }).join();
}


export const _schedule_api2display = (s) => {
  /**
   * Prepare various schedules for display:
   *  - minutes: mm[,mm]*
   *  - minutes and hours from a cron-like pattern: "m h * * *" -> "hh:mm"
   *
   * Normalize all numbes to %02d.
   */
  if (s === null || s === '' || s === undefined) {
    return '';
  }

  let pieces = s.split(' ');
  let minutes = pieces[0];

  minutes = transform(minutes, add_leading_zeros);

  if (pieces.length > 1) {
    // m h * * *
    let hours = pieces[1];

    if (hours === '*') {
      return minutes;

    } else {
      hours = transform(hours, add_leading_zeros);
      return hours + ':' + minutes;
    }
  } else {
    return minutes;
  }
};

export const _schedule_display2api = (s) => {
  /**
   * Prepare various schedules for API:
   *  - minutes: m[,m]*
   *  - hour:minute: "hh:mm" -> "m h * * *"
   *
   * Normalize all numbers to leading zeros removed.
   */
  if (s === null || s === '') {
    return null;
  }

  let pieces = s.split(':');

  if (pieces.length > 1) {
    // hh:mm
    let hours = pieces[0];
    let minutes = pieces[1] === '' ? '0' : pieces[1];

    hours = transform(hours, remove_leading_zeros);
    minutes = transform(minutes, remove_leading_zeros);

    return minutes + ' ' + hours;

  } else {
    let minutes = pieces[0];
    minutes = transform(minutes, remove_leading_zeros);

    return minutes + ' ' + '*';
  }
};