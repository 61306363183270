import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import HeaderLinks from "./HeaderLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";
import SnackbarContent from "../Snackbar/SnackbarContent";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomTooltip from "../CustomTooltip/CustomTooltip.jsx";

function Header({...props}) {
  function makeBrand() {
    let name;
    props.routes.map((prop, key) => {
      if (prop.path === props.location.pathname) {
        name = prop.navbarName;
      }
      return null;
    });

    return name;
  }

  const {classes, color, helpModeActive, activateHelpMode} = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  const location = props.routes.find(el => el.path === props.location.pathname);
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      {props?.masterAlert ?
        <SnackbarContent
          message="Master alert. There are critical alerts."
          color="danger"
        /> : null
      }
      <Toolbar className={classes.container}>
        <div className={classNames(classes.flex, classes.gridContainer)} >
          {!helpModeActive ? (
            <IconButton onClick={() => activateHelpMode({helpModeActive: !helpModeActive})}>
              <HelpOutlineIcon fontSize="medium"/>
            </IconButton>
          ) : (
            <CustomTooltip 
              title={location.description ? location.description : ''} 
            >
              <IconButton onClick={() => activateHelpMode({helpModeActive: !helpModeActive})}>
                <HelpOutlineIcon fontSize="medium" sx={{transform: 'scale(1.1, 1.1)', color: "#9c27b0"}}/>
              </IconButton>
            </CustomTooltip>
          )}
          {/* Here we create navbar brand, based on route name */}
          <Button color="transparent" href="#" className={classNames(classes.title, classes.toCenter)} >
            {makeBrand()}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          <HeaderLinks dbgsrc="Header" pull_parks={true} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger", "gray"])
};

export default withStyles(headerStyle)(Header);
