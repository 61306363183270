import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { connect } from "react-redux";

import CardFooter from "components/Card/CardFooter.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button";
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import { TextField, FormControlLabel, Checkbox, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { getMarketData } from "utils/getDataMethods";
import {logout} from 'utils/auth';

import Highcharts from 'highcharts/highstock';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExportVisiblePeriod
  from 'libs/Highcharts/HighchartsExportVisiblePeriod';
import HighchartsReact from 'highcharts-react-official';

import { buildKUBERequest, security_fetch_params, BULK_LOAD_PERIOD } from "actions";
import moment from 'moment';

import { makeSeries, stocksChartOptions } from 'variables/charts';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import { DATE_FORMAT_DASH } from "constants/general";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  spacing: {
    marginRight: '1rem',
  },
  container: {
    minHeight: '500px',
  },
  loadingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  sizeS: {
    left: 290,
    top: '1.75rem',
    width: 'auto',
    height: 'auto',
  },
  flex: {
    '@media(max-width: 1279px)' : {
      display: 'flex',
      justifyContent: 'center',
    }
  }
};


const DAYS_BEFORE = 92;
const DAYS_AFTER = 2;
const ALL_DATA_ORDER = 5;  // order in Highcharts options

const mapStateToProps = (state) => {
  return {
    apiLabel: state.conn.label,
  };
};
class MarketData extends Component {
  constructor(props) {
    super(props);

    const today = new Date().setHours(0, 0, 0, 0);
    this.state = {
      variables: null,
      selectedVariables: [],
      loading: true,
      loadingChunks: false,
      allDataLoaded: false,
      graphs:{},
      graphsDates:{},
      dateFrom: moment(today).add(-DAYS_BEFORE, 'days'),
      dateTo: moment(today).add(DAYS_AFTER, 'days'),
      minStoredTimestamp: today,
      maxStoredTimestamp: today,
      selectedPeriod: 1, // 1 means "3 months" in Chart Zoom controls
      twoYAxis: false,
      rightYAxis: null,
      leftYAxis: null,
      gettingData: false,
    };
    this.defineDates = this.defineDates.bind(this);
    this.get_markets_variables = this.get_markets_variables.bind(this);
    this.get_variables_data = this.get_variables_data.bind(this);
    this.getMarketAuditData = this.getMarketAuditData.bind(this);
    this.getEdgeTimestamp = this.getEdgeTimestamp.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.chartRef = React.createRef();
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsExportVisiblePeriod(Highcharts);
    Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');
  }

  get_markets_variables() {
    const [url, headers] = buildKUBERequest('/api/market_rich_variables' + `?market_id=${this.props.location.state.data.id}`);
    fetch(url, { method: 'GET', headers: headers, ...security_fetch_params })
      .then((response) => {
        if (response.ok) {
          response.json().then(data => {
            if (data.error) {
              alertify.error("Response error");
              console.log(data.error);
              return
            }

            if (!data.length) {
              alertify.error(`No variables for ${this.props.location.state.data.name} market`);
            }
            const variables = data.map(variable => ({
              name: `${variable.source}/${variable.name}`,
              id: variable.id,
            })).sort((a, b) => a.name > b.name ? 1 : -1);

            this.setState({ variables: variables, loading: false })
          })
        } else if (response.status === 401) {
          logout();
          return;
        } else if (response.status === 404) {
          alertify.error(`No variables for ${this.props.location.state.data.name} market`);
          this.setState({ variables:[], loading: false })
        }
      })
      .catch((error) => { console.log(error) })
  }

  // Get edge timestamp of data
  async getEdgeTimestamp(variable) {
    const marketId = this.props.location.state.data.id;
    const [url, headers] = buildKUBERequest(
      `/api/markets/rich_variable_range/${marketId}/`
      + `${encodeURIComponent(variable)}?field=time&direction=maxmin`,
    );

    const response = await fetch(url, { method: 'GET', headers, ...security_fetch_params });
    if (!response.ok) {
      console.log(`An error has occurred: ${response.status}`);
    } else if (response.status === 401) {
      logout();
      return;
    } else if (response.ok) {
      const data = await response.json();
      if (data.error) {
        alertify.error("Response error");
        console.log(data.error);
        return
      }
      return data;
    }
  }

  getMarketAuditData = async (variable_id, pageNum, rowsPerPage, date_from, date_to) => {
    const dateMin = `&date_min=${date_from}`
    const dateMax = `&date_max=${date_to}`
    const queryStr = `?page=${pageNum}&size=${rowsPerPage}&acknowledged=false${dateMin}${dateMax}&auditor=gap&order_by=-time`;

    try {
      const response = await fetch(`https://kube.sama.energy/gateway/api/markets/${this.props.location.state.data.id}/variables/${variable_id}/audits${queryStr}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      });
  
      if (response.ok) {
        const data = await response.json();
        return data.items;
      } else {
        alertify.error('Error getting audit data!', 5);
        return []
      }
    } catch (err) {
      console.log(err);
      return [];
    }
  }

   get_variables_data(
    isDownloadingByChunks = false, // flag to define continuous downloading
  ) {
    const { selectedVariables, graphsDates } = this.state;
    const marketId = this.props.location.state.data.id;
   
    const that = this;
    let from = this.state.dateFrom;
    let to = this.state.dateTo;

    const calcDataWithGaps = (data, auditData) => {
      if (data.data.length) {
        const startDate = data.data[0][0][0];
        const endDate = data.data[0][data.data[0].length - 1][0];
        const interval = data.data[0].length === 1 
          ? 0 
          : Math.min(...new Set(data.data[0].map((el, i) => {
            if (i === data.data[0].length - 1) {
              return el[0] - data.data[0][i - 1][0];
            }
            return Math.abs(el[0] - data.data[0][i + 1][0]);
          })));
        const fillDatesObject = (startDate, lastDate, interval) => {
          const obj = {}
          if (startDate === lastDate) {
            obj[startDate] = null;
          } else {
            for(let timestamp = startDate; timestamp <= lastDate; timestamp += interval) {
              obj[timestamp] = null;
            }
          };
          return obj;
        }
        const nullishDatesObject = fillDatesObject(startDate, endDate, interval);
        let newDataObj = { ...nullishDatesObject };
        data.data[0].forEach(el => newDataObj[el[0]] = el[1]);
        let newTimestampObj = {...nullishDatesObject};
        data.data[1].forEach(el => newTimestampObj[el[0]] = el[1]);
  
        const dataArr = Object.keys(newDataObj).map(date => [parseInt(date), newDataObj[date]]);
        const timestampArr = Object.keys(newTimestampObj).map(date => [parseInt(date), newTimestampObj[date]]);
        const auditArr = auditData.map(el => moment.utc(el.time).unix() * 1000);
        const noDataArr = Object.keys(newDataObj).map(date => [
          parseInt(date),
          typeof newDataObj[date] === 'number' 
            ? null 
            : auditArr.includes(parseInt(date)) ? parseInt(date) : null  
        ])
        return { dataArr, noDataArr, timestampArr }
      } 

      return { dataArr: [], noDataArr: [], timestampArr: [] }
    }

    selectedVariables.forEach(async (variable) => {
      // check if maxStoredTimestamp is not less than dateFrom
      // if less, then set from to minStoredTimestamp of this variable      
      if (this.state.graphsDates[variable.name] && from.valueOf() > this.state.graphsDates[variable.name].maxStoredTimestamp) {
        from = moment(this.state.graphsDates?.[variable.name].minStoredTimestamp);
      }

      // check if maxStoredTimestamp is bigger than dateTo
      // then set to to maxStoredTimestamp of this variable
      if (this.state.graphsDates[variable.name] && to.valueOf() < this.state.graphsDates[variable.name].maxStoredTimestamp) {
        to = moment(this.state.graphsDates?.[variable.name].maxStoredTimestamp);
      }
    })

    selectedVariables.forEach(async (variable) => {
      // Load data for the specified variable
    
      let allDataLoaded = false;
      let cycleIteration = 1;
      while (!allDataLoaded) {
        const data = await getMarketData(marketId, variable.name, from, to, that.props.apiLabel); 
        const auditData = await this.getMarketAuditData(
          variable.id, 
          1, 
          100, 
          from.format(DATE_FORMAT_DASH), 
          to.format(DATE_FORMAT_DASH)
        );
        let state = {};

        if (isDownloadingByChunks) {
          // Otherwise, shift date period and continue
          to = from;
          from = moment(to).add(-BULK_LOAD_PERIOD, 'months');

          if (cycleIteration === 1 && !this.state.graphs[variable.name][0].length) {
            alertify.error(`No data for: ${variable.name}!`)
          }
          cycleIteration = 0;

          // If "from" date is earlier than minimum stored timestamp
          if (from < moment(graphsDates[variable.name].minStoredTimestamp).add(-1,'days').valueOf()) {
            allDataLoaded = true;
            state = {
              allDataLoaded,
              loadingChunks: false,
              loading: false,
            };
            if (!this.state.graphs[variable.name][0].length) alertify.error(`No data for: ${variable.name}!`)
          }

          const { dataArr, noDataArr, timestampArr } = calcDataWithGaps(data, auditData);

          state = {
            ...state,
            graphs: {
              ...this.state.graphs,
              [variable.name]: [
                [
                  ...(dataArr),
                  ...this.state.graphs[variable.name][0],
                ],
                [
                  ...(noDataArr),
                  ...this.state.graphs[variable.name][1],
                ],
                [
                  ...(timestampArr),
                  ...this.state.graphs[variable.name][2],
                ],
              ],
            }
          };

        } else {
          // If it's partial API call, finish downloading after
          // the first iteration
          allDataLoaded = true;
          
          if (!data.data.length) alertify.error(`No data for: ${variable.name}!`);
          const { dataArr, noDataArr, timestampArr } = calcDataWithGaps(data, auditData);

          state = {
            graphs: {...this.state.graphs, [variable.name]: [dataArr, noDataArr, timestampArr]},
            loading: false,
            gettingData: false,
          };
        }

        this.setState(state);
      }
    })
    
  }

  componentDidMount() {
    this.get_markets_variables();
  }

  componentDidUpdate(prevProps, prevState) {
    // For some reason HighCharts lib doesn't make active selected period
    // button after data was loaded. Maybe, because of re-render
    if (((!this.state.loadingChunks && prevState.loadingChunks)
      || (!this.state.loading && !this.state.loadingChunks && prevState.loading))
      && this.chartRef.current?.chart?.hasRendered) {
      const { buttons } = this.chartRef.current.chart.rangeSelector;
      buttons[this.state.selectedPeriod].element.hcEvents.click[0].fn();
    }
  }

  defineDates = (value) => {
    if (!value) return;
    
    // Reset state
    this.setState({
      allDataLoaded: false,
      graphs: {},
      graphsDates: {},
      selectedPeriod: 1,
      twoYAxis: false,
    });
   
    value.forEach(async variable => {
      const today = new Date().setHours(0, 0, 0, 0);
      const storedTimestampData = await this.getEdgeTimestamp(variable.name, true);
      const { max, min } = storedTimestampData?.data;

      const dateFrom = moment(max ?? today).add(-DAYS_BEFORE, 'days');
      const dateTo = moment(max ?? today).add(DAYS_AFTER, 'days');

      // Update the from/to period & the latest stored timestamp value
      this.setState({
        graphsDates: {
          ...this.state.graphsDates,
          [variable.name]: {
            minStoredTimestamp: min ?? today,
            maxStoredTimestamp: max ?? today,
          }
        },
        dateFrom,
        dateTo,
        minStoredTimestamp: min ?? today ,
        maxStoredTimestamp: max ?? today ,
      });
    })
  };

  handleVariableChange = (event, value) => {
    this.setState({selectedVariables: value})
    this.defineDates(value);
  };

  handleCheckChange = () => {
    this.setState({twoYAxis: !this.state.twoYAxis})
  };

  handleButtonClick = () => {
    this.setState({
      allDataLoaded: false,
      selectedPeriod: 1,
      twoYAxis: false,
      gettingData: true,
    });
    this.get_variables_data()
  }

  render() {
    const that = this;
    const handleTimePeriodClick = function () {
      const { maxStoredTimestamp } = that.state;
      const dateFrom = this.type === 'ytd'
        ? moment(`${new Date(maxStoredTimestamp).getFullYear()}-01-01 00:00:00`)
        : moment(maxStoredTimestamp).add(-this.count, this.type);
      const dateTo = moment(maxStoredTimestamp).add(DAYS_AFTER, 'days');

      let state = { selectedPeriod: this.order };
      let callback = null;
      if (that.state.dateFrom > dateFrom && !that.state.allDataLoaded) {
        state = {
          ...state,
          loading: true,
          dateFrom,
          dateTo,
        };
        callback = that.get_variables_data;
      }
      that.setState(state, callback);
      return true;
    }
    
    const handleTimePeriodAllClick = function () {
      
      const { maxStoredTimestamp } = that.state;
      const dateFrom = moment(maxStoredTimestamp)
        .add(-BULK_LOAD_PERIOD, 'months');
      const dateTo = moment(maxStoredTimestamp).add(DAYS_BEFORE, 'days');
      if (!that.state.loadingChunks && !that.state.allDataLoaded) {
        that.setState({
          loading: true,
          loadingChunks: true,
          dateFrom,
          dateTo,
          selectedPeriod: this.order,
        }, () => that.get_variables_data(true));
      }
      return true;
    }
    
    let r = 0;
    let l = 0;
    const seriesAndAxis = () => {
      const data = Object.keys(this.state.graphs).map((variable, index)=> {
        return this.state.graphs[variable].map((graph, id) => {
          
          if (Object.keys(this.state.graphs).length === 2 && id === 0) {
            graph.forEach(arr => {

              if (index === 0) {
                if (Math.abs(arr[1]) >= r) {
                  r = Math.abs(arr[1])
                } 
              }
              if (index === 1) {
                if (Math.abs(arr[1]) >= l) {
                  l = Math.abs(arr[1])
                }
              }
            })  
          }

          if (index === 0 && id === 0) return makeSeries(`${variable} value`, graph, {
            connectNulls: true,
          });
          if (index > 0 && id === 0) return makeSeries(`${variable} value`, graph, {
            yAxis: this.state.twoYAxis ? 2 : 0,
            connectNulls: true, 
          });
          if (id === 1) return makeSeries(`${variable} gap`, graph, {
            yAxis: 1,
            tooltip: {
              // Note: Use regular function instead of arrow one
              // to have TooltipFormatterContext as this
              pointFormatter: function () {
                return (
                  `<span style="color:${this.color}">\u25CF</span>` +
                  ` ${variable} gap timestamp: <b>` +
                  moment.utc(this.y).format('Y-MM-DD HH:mm:SS') +
                  '</b><br/>'
                );
              },
            },
            marker: {
              enabled: true,
              radius: 7
            }
          });
          return makeSeries(
            `${variable} source time`,
            graph,
            {
              yAxis: 1,
              tooltip: {
                // Note: Use regular function instead of arrow one
                // to have TooltipFormatterContext as this
                pointFormatter: function () {
                  return `<span style="color:${this.color}">\u25CF</span>`
                    + ' Source timestamp: <b>'
                    + moment.utc(this.y).format('Y-MM-DD HH:mm:SS')
                    + '</b><br/>';
                },
              },
              dashStyle: 'Dot',
              visible: false,
            },
            );
          })
        })

        const yAxis = () => {
          if (this.state.twoYAxis) {
            return [
              {
                title: {
                  text: `value of ${Object.keys(this.state.graphs)[0]}`,
                },
                min: Math.round(-r),
                max: Math.round(r),
              },
              {
                title: {
                  text: 'source timestamp, UTC'
                },
                type: 'datetime',
                opposite: true,
              },
              {
                title: {
                  text: `value of ${Object.keys(this.state.graphs)[1]}`,
                },
                opposite: false,
                min: Math.round(-l),
                max: Math.round(l),
              },
            ]
          } else {
            return [
              {
                title: {
                  text: 'value',
                },
                min: null,
                max: null
              },
              {
                title: {
                  text: 'source timestamp, UTC'
                },
                type: 'datetime',
                opposite: true,
              },
            ]
          }
        }
      
        return {
          series: data.flat().sort((a, b) => a.data.length - b.data.length),
          yAxis: [...yAxis()],
        } 
    }

    const options = {
      ...stocksChartOptions({
        filename: this.state.variable,
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 1,
              text: '1m',
              title: 'View 1 month',
              order: 0,
              events: {
                click: handleTimePeriodClick,
              },
            }, {
              type: 'month',
              count: 3,
              text: '3m',
              title: 'View 3 months',
              order: 1,
              events: {
                click: handleTimePeriodClick,
              },
            }, {
              type: 'month',
              count: 6,
              text: '6m',
              title: 'View 6 months',
              order: 2,
              events: {
                click: handleTimePeriodClick,
              },
            }, {
              type: 'ytd',
              text: 'YTD',
              title: 'View year to date',
              order: 3,
              events: {
                click: handleTimePeriodClick,
              },
            }, {
              type: 'year',
              count: 1,
              text: '1y',
              title: 'View 1 year',
              order: 4,
              events: {
                click: handleTimePeriodClick,
              },
            }, {
              type: 'all',
              text: 'All',
              title: 'View all',
              order: ALL_DATA_ORDER,
              events: {
                click: handleTimePeriodAllClick,
              },
            },
          ],
          selected: this.state.selectedPeriod,
        },
      }),
      xAxis: {
        min: this.state.dateFrom.valueOf(),
        max: moment().utc().add(DAYS_AFTER, 'days').valueOf(),
      },
      navigator: {
        baseSeries: 0
      },
      // yAxis: [...yAxis()],
      // series: [...series()],
      ...seriesAndAxis(),
    }

    const { classes } = this.props;
    const smallLoader = (this.state.loading || this.state.loadingChunks) ? (
      <div className={
        Object.keys(this.state.graphs).length
          ? classNames(
            classes.loadingContainer,
            classes.sizeS,
          )
          : classes.loadingContainer
      }>
        <div
          className={
            Object.keys(this.state.graphs).length
              ? classNames('loader', 'sizeS')
              : 'loader'
          }
          alt="Loading report..."
        />
      </div>
    ) : null;

    const loader = (
      <div className={classes.loadingContainer}>
        <div className="loader" alt="Loading report..."/>
      </div>
    );

    const noDataToDisplay = (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography>No data to display...</Typography>
        </Grid>
      </Grid>
    )

    const graph = (
      <Card style={{ backgroundColor: '#EEEEEE' }}>
        <CardBody style={{ backgroundColor: '#EEEEEE' }}>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.container}>
              {smallLoader}
              {Object.keys(this.state.graphs).length > 0 && (
                <HighchartsReact
                  ref={this.chartRef}
                  highcharts={Highcharts}
                  constructorType={'stockChart'}
                  options={options}
                />
              )}
            </Grid>
          </Grid>
        </CardBody>
        <CardFooter
          style={{
            backgroundColor: '#EEEEEE',
            justifyContent: 'flex-start',
          }}
        >
          <Button
            className={classes.spacing}
            color="primary"
            onClick={this.handleButtonClick}
            tooltip="Refreshes the chart"
            helpModeActive={this.props.helpModeActive}
          >
            Refresh
          </Button>
        </CardFooter>
      </Card>
    )

    let graphContainer = null;


    if(!Object.keys(this.state.graphs).length && !(this.state.loading || this.state.loadingChunks))
      if(this.state.gettingData)
        graphContainer = loader
      else
        graphContainer = noDataToDisplay
    else if (this.state.selectedVariables.length !== Object.keys(this.state.graphs).length)
      graphContainer = loader
    else
      graphContainer = graph


    if (this.state.variables) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card style={{ backgroundColor: '#EEEEEE' }}>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Select variable for{' '}
                  {this.props.location.state.data.name} Market
                </h4>
              </CardHeader>
              <CardBody >
                <Grid container spacing={4} justifyContent='center'>
                  <Grid item xs={8} sm={8} lg={5}>
                    <FormControl
                      className={classes.formControl}
                      style={{ width: '100%' }}
                    >
                      <Autocomplete 
                        id="variable_name"
                        autoComplete
                        multiple
                        filterSelectedOptions
                        options={this.state.variables}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Variable Name:" />}
                        onChange={this.handleVariableChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4} lg={3}>
                    <Button
                      style={{width: '100%'}}
                      onClick={this.handleButtonClick}
                      disabled={this.state.selectedVariables.length ? false : true}
                      tooltip="Load data for the selected variables"
                      helpModeActive={this.props.helpModeActive}
                    >
                      Get Data
                    </Button>
                  </Grid>
                  <Grid item xs={8} sm={5} lg={3}>
                    <FormControlLabel 
                      className={classes.flex}
                      style={{width: '100%'}}
                      control={
                        <CustomTooltip 
                          title='Enables the use of dual Y-axes, simplifying the comparison of variables with different value scales'
                          disableFocusListener={!this.props.helpModeActive}
                          disableHoverListener={!this.props.helpModeActive}
                          disableTouchListener={!this.props.helpModeActive}
                        >
                          <Checkbox 
                            color="primary"
                            id="twoYAxis"
                            checked={this.state.twoYAxis}
                            value={`${this.state.twoYAxis}`}
                            onChange={this.handleCheckChange}
                            disabled={this.state.selectedVariables.length === 2 && (Object.keys(this.state.graphs).length === 0 || Object.keys(this.state.graphs).length === 2) ? false : true}
                          />
                        </CustomTooltip>
                      }
                      label='Show two Y axes'
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </Grid>

          <Grid item xs={12}>
            {graphContainer}
          </Grid>
        </Grid>
      )
    } else {
      return loader
    }
  }
}

const MarketDataConnect = connect(mapStateToProps)(MarketData);
export default withStyles(styles)(MarketDataConnect);
