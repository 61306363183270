import React, { Component } from 'react';

import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import { Textsms } from '@material-ui/icons';
import { Warning } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { primaryColor } from 'assets/jss/material-dashboard-react';

class Jobs extends Component {
  componentDidMount() {}

  render() {
    const jobs = this.props.jobs;
    const { classes, tableData, winHeight } = this.props;

    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontWeight: 500,
      fontSize: '1em',
      padding: '12px 0',
      textAlign: 'center',
    };

    const columns = [
      {
        title: 'Name',
        field: 'name',
        width: 200,
      },
      {
        title: 'Enqueued',
        field: 'enqueued',
        width: 100,
      },
      {
        title: 'Finished',
        field: 'finished',
        width: 100,
      },
      {
        title: 'Log',
        field: 'log',
        width: 60,
      },
      {
        title: 'Errors',
        field: 'errors',
        width: 60,
      },
      {
        title: 'Warnings',
        field: 'warnings',
        width: 75,
      },
      {
        title: 'Crash info',
        field: 'crash_info',
        width: 75,
      },
      {
        title: 'Finished',
        field: 'status',
        width: 85,
      },
    ];
    const formatedTableData = tableData.map((x) => {
      const enqueuedDate = x[2] ? moment.tz(x[2],x[2].split(' ').reverse()[0]).format('MMM DD HH:mm') : null;
      const finishedDate = x[3] ? moment.tz(x[3],x[3].split(' ').reverse()[0]).format('MMM DD HH:mm') : null;
      return {
        name: x[1],
        enqueued: enqueuedDate,
        finished: finishedDate,
        log: x[4] ? true : false,
        errors: x[5] || 0,
        warnings: x[6]?.length ? true : false,
        crash_info: x[7] ? true : false,
        status: x[8] === 'finished' ? true : false,
      };
    });
    const renderData = (data) => {
      const index = data.tableData.id;
      const dataJobs = { ...data };
      delete dataJobs[0];
      delete dataJobs.tableData;
      return (
        <TableRow>
          <TableCell>{dataJobs.name}</TableCell>
          <TableCell align="center">
            {dataJobs.enqueued}
          </TableCell>
          <TableCell align="center">
            {dataJobs.finished}
          </TableCell>
          <TableCell align="center">
            {jobs.jobs.rqjobs[index].meta?.log?.length ?
            <Link
              to={{
                pathname: '/job_log',
                state: {
                  data: jobs.jobs.rqjobs[index].meta.log,
                  type: 'log',
                },
              }}
            >
              <Textsms />
            </Link> : <></>}
          </TableCell>
          <TableCell>{dataJobs.errors}</TableCell>
          <TableCell align="center">
            {jobs.jobs.rqjobs[index].meta?.warning_messages?.length ?
            <Link
              to={{
                pathname: '/job_log',
                state: {
                  data: jobs.jobs.rqjobs[index].meta.warning_messages,
                  type: 'warning',
                },
              }}
            >
              <Warning />
            </Link>
            : <></>}
          </TableCell>
          <TableCell align="center">
            {jobs.jobs.rqjobs[index].exc_info ?
            <Link
              to={{
                pathname: '/job_log',
                state: {
                  data: [jobs.jobs.rqjobs[index].exc_info],
                  type: 'error',
                },
              }}
            >
              <Warning />
            </Link>
            : <></>}
          </TableCell>
          <TableCell align="center">{dataJobs.status ? 'Yes' : 'No'}</TableCell>
        </TableRow>
      );
    };

    return (
      <div className={classes.tableResponsive}>
        <MaterialTable
          columns={columns}
          data={formatedTableData}
          options={{
            toolbar: true,
            showTitle: false,
            tableLayout: 'fixed',
            paging: false,
            maxBodyHeight: winHeight,
            headerStyle: headerStyle,
            emptyRowsWhenPaging: false,
            pageSize: 6,
            draggable: false,
            search: true,
          }}
          components={{
            Row: ({ data }) => renderData(data),
          }}
        />
      </div>
    );
  }
}

Jobs.defaultProps = {
  tableHeaderColor: 'primary',
};

Jobs.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.array])),
};

export default withStyles(tableStyle)(Jobs);
