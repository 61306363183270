import { RECEIVED_JOBS_DATA } from "constants/action-types";

import { LOADING_JOBS_DATA } from "constants/action-types";
import moment from 'moment';

const initialState = {
  jobs: [],
  tableHead: ['Name', 'Enqueued', 'Finished', 'Log', 'Warnings', 'Status'],
  tableData: [],
  loading: true,
};

export default (state = initialState, action) => {
  if (action.type === LOADING_JOBS_DATA) {
     return { ...state, ...initialState };
  } else if (action.type === RECEIVED_JOBS_DATA) {
    if (typeof (action.jobs.data) !== 'undefined') {
      const {data} = action.jobs;
      const jobs = data.rqjobs.map(el => {
        if (el.name.includes('%7B')) {
          return {...el, name: decodeURIComponent(el.name)}
        } else if (el.name.includes('for all parks')) {
          return el
        } else {
          let obj = {}
          new URLSearchParams(el.name).forEach((value, key) => obj[key] = value)
          return {...el, name: JSON.stringify(obj)};
        }
      }).sort((a, b) => (
        moment(a.enqueued_at).unix() > moment(b.enqueued_at).unix()
          ? -1 : 1
      ));
      return {
        ...state,
        jobs: data,
        tableHead: initialState.tableHead,
        tableData: jobs.map((x) => [
          x.id_hash,
          x.name,
          x.enqueued_at,
          x.ended_at,
          x.meta?.log,
          x.meta?.error_count,
          x.meta?.warning_messages,
          x.exc_info,
          x.status,
        ]),
        loading: false,
      };
    }
  }

  return state;
};
