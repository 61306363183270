import { FormControl, TextField, Grid, IconButton } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { ExportCsv } from '@material-table/exporters';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsDrilldown from 'highcharts/modules/drilldown';

import { get_markets_data, buildAPIRequest, security_fetch_params } from 'actions';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import 'moment-timezone';
import * as helper from './PNLAnalysisHelper';
import { STRATEGIES } from 'constants/general';
import NoData from 'components/NoData';

import checkboxAdnRadioStyle from "assets/jss/material-dashboard-react/checkboxAdnRadioStyle.jsx";

import { downloadParks } from 'utils/getDataMethods';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';

import localForage from "localforage";
import alertify from 'alertifyjs';
import { logout } from "utils/auth";
import CustomMap from 'components/Map/Map';

const styles = {
  ...checkboxAdnRadioStyle
};

const mapStateToProps = (state) => {
  return {
    markets: state.markets.markets,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMarkets: () => dispatch(get_markets_data()),
});

const WorldwideRenewables = (props) => {
  const [apisVolumes, setApisVolumes] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [marketsData, setMarketsData] = useState(null);
  const [clientsData, setClientsData] = useState(null);
  const [selectedMarketsStatus, setSelectedMarketsStatus] = useState('Total');
  const [selectedMarketsType, setSelectedMarketsType] = useState('Both');
  const [selectedClientStatus, setSelectedClientsStatus] = useState('Total');
  const [selectedClientsType, setSelectedClientsType] = useState('Both');
  const [marketsType, setMarketsType] = useState('Both');
  const [clientsType, setClientsType] = useState('Both');

  HighchartsDrilldown(Highcharts);

  const clients = {
    "AXPO DE": "AXPO DE",
    "AXPO PL": "AXPO PL",
    "AXPO-DE": "AXPO DE",
    "BEE": "BEE",
    "DOLOMITI ENERGIA": "Dolomiti Energia",
    "E2M": "E2M",
    "ENEOS": "Eneos",
    "ENEXIA": "Enexia",
    "ENGIE": "Engie",
    "ENNET": "Ennet",
    "EWZ": "EWZ",
    "ELECTROROUTE": "Electroroute",
    "ENECO": "Eneco",
    "ENECO TEST": "Eneco",
    "ENERGY MC": "Energy MC",
    "ENERGYMC": "Energy MC",
    "ENERY": "Enery",
    "FRI-EL": "Fri-el",
    "GB": "GB",
    "GLOBALTECHONE": "GlobalTechOne",
    "IWB": "IWB",
    "JPOWER": "Jpower",
    "LOOOP": "Looop",
    "MARUB": "Marubeni",
    "MARUBENI": "Marubeni",
    "PURE ENERGY": "Pure Energy",
    "PUREENERGY": "Pure Energy",
    "RESPECT": "Respect",
    "RESPECT ENERGY": "Respect",
    "SAR-IBR": "SAR-IBR",
    "SUNNIC": "Sunnic",
    "TINMAR": "Tinmar",
    "UNIPER": "Uniper",
    "WET": "WET",
    "WINDENERGYTRADING": "WET"
  };

  const { classes, getMarkets, conn } = props;

  const getParkData = async (API, park) => {
    const forageStr = `park_data_${park.id}_${API.API_NAME}`;
    const storedData = await localForage.getItem(forageStr);

    if (!storedData || storedData.updated_at < moment().unix() - 3600) {
      const url = `${API.API_URL}/api/v1/parks/${park.id}`;
      const [, headers] = buildAPIRequest('/api/v1/parks');
      const response = await fetch(url, {
        method: 'GET',
        headers,
        ...security_fetch_params
      });

      if (response.ok) {
        const data = await response.json();
        localForage.setItem(forageStr, {data: data.data, updated_at: moment().unix()});
        return data.data;
      } else if (response.status === 401) {
        logout();
        return;
      } else {
        alertify.error(`Error getting ${API.API_NAME} ${park.name} data!`, 7);
        return null;
      }
    }

    return storedData.data;
  }

  const getParksAPIS = async () => {
    let stratsObj = {};
    const instanceForData = ['BEE', 'Enexia', 'EWZ', 'globaltechone', 'Strat-customers', 'Trial', 'Uniper'];
    const APIS = conn.loginInstances;
    const instList = Object.keys(APIS).filter(API_NAME => instanceForData.includes(API_NAME)).sort((a, b) => a > b);
    const instanceStrategies = instList.map(async (instance) => {
      let apiStrategies = [];
      const instStrategies = STRATEGIES.map(async (strategy) => {
        const parks = await downloadParks(strategy, APIS[instance].API_URL);
        const parksData = parks ? parks.data
          .filter(park =>  {
            if (instance === 'BEE' && park.id === 200) return true;
            else if (
              instance !== 'BEE' && 
              park.id !== 123105 && 
              park.id !== 100 && 
              park.id !== 1 && 
              park.id !== 2 && 
              park.id !== 3
            ) return true;
            else return false;
          })
          .map(async (park) => {
            const detailedPark = await getParkData(APIS[instance], park);

            if (detailedPark) {
              if (instance === 'Strat-customers' || instance === 'Trial')  {
                const clientName = park.name.split(') ')[park.name.split(') ').length -1].toUpperCase();
                const Pmax = detailedPark.optimization_job.entry_over?.[0]?.Pmax 
                  ? detailedPark.optimization_job.entry_over[0].Pmax
                  : detailedPark.optimization_job.Pmax;
                return {
                  ...park, 
                  client: clients[clientName], 
                  status: instance === 'Trial' ? 'Trial' : 'Live', 
                  type: 'Flat Volume',
                  tradedVolume: parseFloat(Pmax.toFixed(3)),
                  city: detailedPark.location.city,
                  country: detailedPark.location.country_name,
                  lat: detailedPark.location.lat,
                  lng: detailedPark.location.lon,
                }
              }

              return {
                ...park, 
                client: clients[instance.toUpperCase()], 
                status: instance === 'Uniper' || (instance === 'EWZ' && park.id === 200) ? 'Live' : 'Trial', 
                type: 'Physical Park', 
                tradedVolume: parseFloat(detailedPark.capacity.toFixed(3)),
                city: detailedPark.location.city,
                country: detailedPark.location.country_name,
                lat: detailedPark.location.lat,
                lng: detailedPark.location.lon,
              }
            } else return null;
          }) : [];
        
        const stratParks = await Promise.all(parksData);
        apiStrategies.push({[strategy]: stratParks.filter(el => el)});

        return {[strategy]: stratParks.filter(el => el)};
      });

      const stratArr = await Promise.all(instStrategies);

      let strategiesObj = {};
      stratArr.forEach(strategyObj => {
        strategiesObj = {...strategiesObj, ...strategyObj}
      });

      return [instance === 'globaltechone' ? 'GlobalTechOne' : instance, strategiesObj];
    });
    
    const allStrategiesArr = await Promise.all(instanceStrategies);
    stratsObj = Object.fromEntries(allStrategiesArr);

    let typeObj = {
      'Physical Park': {},
      'Flat Volume': {}
    }
    Object.entries(stratsObj).forEach((instanceArr) => {
      const [instance, dataObj] = instanceArr;
      Object.entries(dataObj).forEach((strategyArr) => {
        const [strategy, parks] = strategyArr;
        parks.forEach(park => {

          if (park.type === 'Flat Volume') {
            typeObj[park.type][park.country] = {
              ...typeObj[park.type][park.country],
              lat: park.lat,
              lng: park.lng,
              totalVolume: typeObj[park.type][park.country]?.totalVolume 
                ? typeObj[park.type][park.country].totalVolume + park.tradedVolume : park.tradedVolume,
            }
            return
          }

          typeObj[park.type][park.country] = {
            ...typeObj[park.type][park.country],
            [park.city]: {
              lat: park.lat,
              lng: park.lng,
              parks: typeObj[park.type][park.country]?.[park.city]?.parks 
              ? [...typeObj[park.type][park.country][park.city].parks, park] : [park]
            }
          }
        })
      })
    })
    setMarkers(typeObj);
    setApisVolumes(stratsObj);
  }
  
  // Get markets and parks
  useEffect(() => {
    getMarkets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(Object.keys(conn.loginInstances).length)
      getParksAPIS();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[conn]);

  const calcVolumesData = (mainKey) => {
    let dataObject = {};
    Object.entries(apisVolumes).flatMap(([instance, instanceData]) => {
      return Object.entries(instanceData).flatMap(([strategy, strategyArr]) => {
        return strategyArr.forEach(park => dataObject[park[mainKey]] = dataObject[park[mainKey]] 
          ? [...dataObject[park[mainKey]], {...park, strategy, instance}] : [{...park, strategy, instance}]
        )
      })
    });
    
    return dataObject;
  }

  useEffect(() => {
    if (apisVolumes) {      
      const marketsData = calcVolumesData('market');
      const clientsData = calcVolumesData('client');
      
      setMarketsData(marketsData);
      setClientsData(clientsData);
    }
  }, [apisVolumes]);

  const formStatusData = (obj, type) => {
    const data = Object.entries(obj).sort((a, b) => a[0] > b[0] ? 1 : -1).map(([key, parks]) => {
      const filtered = type ? parks.filter(park => park.type === type) : [...parks];
      const live = filtered
        .filter(park => park.status === 'Live')
        .reduce((acc, park) => acc + park.tradedVolume, 0);
      const trial = filtered
        .filter(park => park.status === 'Trial')
        .reduce((acc, park) => acc + park.tradedVolume, 0);
      const total = live + trial;
      return [key, live, trial, total]
    })

    let totalRow = ['Total', 0, 0, 0];
    data.forEach((row) => row.forEach((val, i) => i > 0 ? totalRow[i] += val : null))

    return [...data, [...totalRow]]
  }

  const formStrategyData = (obj, status, type) => {
    const data = Object.entries(obj).sort((a, b) => a[0] > b[0] ? 1 : -1).map(([market, parks]) => {
      const fStatus = status ? parks.filter((park) => park.status === status) : [...parks];
      const fStatusAndType = type ? fStatus.filter((park) => park.type === type) : [...fStatus];
      let stratRow = [...STRATEGIES, 'Total'].reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});
      fStatusAndType.forEach(park => {
        stratRow[park.strategy] += park.tradedVolume;
        stratRow['Total'] += park.tradedVolume;
      })

      return [market, ...Object.values(stratRow)]
    })

    let totalRow = ['Total',  ...STRATEGIES.map(() => 0), 0];
    data.forEach((row) => row.forEach((val, i) => i > 0 ? totalRow[i] += val : null));

    return [...data, totalRow];
  }

  const mStatusData = useMemo(() => {
    return marketsData ? formStatusData(marketsData, marketsType !== 'Both' ? marketsType : '') : [];
  }, [marketsData, marketsType]);

  const cStatusData = useMemo(() => {
    return clientsData ? formStatusData(clientsData, clientsType !== 'Both' ? clientsType : '') : [];
  }, [clientsData, clientsType]);

  const mStratData = useMemo(() => {
    return marketsData ? formStrategyData(
        marketsData, 
        selectedMarketsStatus === 'Total' ? '' : selectedMarketsStatus, 
        selectedMarketsType === 'Both' ? '' : selectedMarketsType
      ) : [];
  }, [marketsData, selectedMarketsStatus, selectedMarketsType]);

  const cStratData = useMemo(() => {
    return clientsData ? formStrategyData(
        clientsData, 
        selectedClientStatus === 'Total' ? '' : selectedClientStatus, 
        selectedClientsType === 'Both' ? '' : selectedClientsType
      ) : [];
  }, [clientsData, selectedClientStatus, selectedClientsType]);

  const overviewTableData = useMemo(() => {
    if (apisVolumes) {
      // Summarizing volumes considering the instance, client, market, strategy, status and type
      const obj = {};
      for (const [instance, instanceData] of Object.entries(apisVolumes)) {
        for (const [strategy, parks] of Object.entries(instanceData)) {
          for (const park of parks) {
            if (!obj[instance]) {
              obj[instance] = {};
            }

            const clientKey = obj[instance];
            if (!clientKey[park.client]) {
              clientKey[park.client] = {}
            }

            const marketKey = clientKey[park.client];
            if (!marketKey[park.market]) {
              marketKey[park.market] = {};
            }

            const strategyKey = marketKey[park.market];
            if (!strategyKey[strategy]) {
              strategyKey[strategy] = {};
            }

            const statusKey = strategyKey[strategy];
            if (!statusKey[park.status]) {
              statusKey[park.status] = {};
            }

            const typeKey = statusKey[park.status];
            if (!typeKey[park.type]) {
              typeKey[park.type] = {};
            }

            const data = typeKey[park.type];
            if(!data['tradedVolume']) {
              data['tradedVolume'] = 0;
            }
            if (!data['parks']) {
              data['parks'] = [];
            }

            data['tradedVolume'] += park.tradedVolume || 0;
            data['parks'].push(park.name);
          }
        }
      } 

      // Preparing summarized volumes data for table
      const tableData = Object.entries(obj).flatMap(([instance, instanceData]) => {
        return Object.entries(instanceData).flatMap(([client, clientData]) => {
          return Object.entries(clientData).flatMap(([market, marketData]) => {
            return Object.entries(marketData).flatMap(([strategy, strategyData]) => {
              return Object.entries(strategyData).flatMap(([status, statusData]) => {
                return Object.entries(statusData).map(([type, typeData]) => {
                  const { tradedVolume, parks } = typeData;
                  return [instance, client, market, strategy, status, type, parks.join(', '), tradedVolume];
                });
              })
            })
          })
        })
      })

      return tableData
    } else {
      return []
    }
  }, [apisVolumes])

  const statusOptions = ['Total', 'Live', 'Trial'];
  const typeOptions = ['Both', 'Physical Park', 'Flat Volume'];
  const overviewColumns = ['Instance', 'Client', 'Market', 'Strategy', 'Status', 'Type', 'Parks', 'Volume (MW)'];

  const makeColumn = (headsArr) => {
    return headsArr.map((head, index) => {
      return {
        title: head,
        field: `${index}`,
        width: 80,
        sorting: false,
      };
    });
  };

  const makeRows = (dataArr) => {
    const rows = dataArr.map((rowArr, index) => {
      const obj = {};
      rowArr.map((data, index) => (typeof data === 'number' 
        ? obj[index] = parseFloat(data.toFixed(2)) : obj[index] = data
      ));
      obj['id'] = index;
      return obj;
    });
    return rows;
  };
  
  let arr = [];
  const renderTableRow = (data, lastIndex = null) => {
    const period = { ...data };
    delete period.tableData;
    delete period.id;
    if(data[0].split(": ").length > 1 && !arr.includes(data[0].split(": ")[0])) arr.push(data[0].split(": ")[0]);
    
    const index = data[0].split(": ").length > 1 && 
      (arr.indexOf(data[0].split(": ")[0]) + 1) % 2 === 0 ? arr.indexOf(data[0].split(": ")[0]) : null
    
    return (
      <TableRow hover key={data.tableData.id} id={data.tableData.id} style={index ? {backgroundColor: "aliceblue"} : {}}>
        {Object.keys(period).map((key, index) => {
          return (
            <TableCell
              key={key + index}
              className={classes.fundamentalCell}
              align="center"
              style={
                lastIndex && data.tableData.id === lastIndex && data[0] === 'Total'
                  ? { fontWeight: 'bold' }
                  : null
              }
            >
              {typeof period[key] === 'number' ? period[key].toLocaleString() : period[key]}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const exportFunc = (cols, datas) => {
    const colsExport = cols.map((col) => ({
        ...col,
        title: col.titleExport ? col.titleExport : col.title,
    }));
    return ExportCsv(colsExport, datas, 'Worldwide Renewables');
  };

  const renderTable = (tableHead, tableData, showSearch) => {
    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontSize: '1em',
      padding: '12px 0',
      textAlign: 'center',
      fontWeight: 500,
    };
    return (
      <Grid item xs={12}>
        <Card>
          <CardBody>
            <div className={classes.tableResponsive}>
              <MaterialTable
                columns={makeColumn(tableHead)}
                data={makeRows(tableData)}
                options={{
                  showTitle: false,
                  tableLayout: 'fixed',
                  paging: false,
                  maxBodyHeight: window.innerHeight - 280,
                  headerStyle: headerStyle,
                  emptyRowsWhenPaging: false,
                  pageSize: 6,
                  draggable: false,
                  search: showSearch,
                  exportMenu: [
                    {
                      label: 'Export CSV',
                      exportFunc: exportFunc,
                    },
                  ],
                }}
                components={{
                  Row: ({ data }) =>
                    renderTableRow(
                      data,
                      makeRows(tableData).length - 1
                    ),
                }}
              />
            </div>
          </CardBody>
        </Card>
      </Grid>
    )
  };

  const renderDrilldownPieChart = (data, title, isSummary) => {
    const status = ['Live', 'Trial'];
    const s = data
      .filter(el => el[0] !== 'Total')
      .map(arr => ({ name: arr[0], y: arr[arr.length - 1], drilldown: arr[0]}));
    const d = data
      .filter(el => el[0] !== 'Total')
      .map(arr => ({
        name: arr[0],
        id: arr[0],
        data: arr
          .filter((_, i) => i !== 0 && i !== arr.length - 1)
          .map((el, i) => [isSummary ? status[i] : STRATEGIES[i], el])
      }))

    return (
      <Grid container>
        <Grid item xs={12}>
          <HighchartsReact
            highcharts={Highcharts}
            immutable={true}
            options={{
              chart: {
                type: 'pie',
                height: 600
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    style: {
                      fontSize: '14px'
                    }
                  },
                  showInLegend: true,
                  ignoreHiddenPoint: false,
                  minSize: 10,
                  borderWidth: 1,
                  borderColor: '#fff'
                },
              },
              legend: {
                itemStyle: {
                  fontSize: '14px'
                }
              },
              tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: ' +
                    '<b>{point.y:.2f}, MW'
              },
              title: false,
              series: [{
                name: `${title === 'clients' ? 'Clients' : 'Markets'}`,
                colorByPoint: true,
                data: [...s]
              }],
              drilldown: {
                series: [...d]
              }
            }}
          />
        </Grid>
      </Grid>
    )
  };

  const content = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>{'Map'}</CardHeader>
          <CardBody>
            <CustomMap markers={markers}/>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>{'Volumes per Market (MW)'}</CardHeader>
          <CardBody>
            {mStatusData.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                    <Autocomplete
                      id="marketsType"
                      options={typeOptions}
                      value={marketsType}
                      getOptionLabel={(option) => option}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField {...params} label="Type" />
                      )}
                      onChange={(_, v) => setMarketsType(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} xl={8}>
                      {renderTable(['Market', 'Live (MW)', 'Trial (MW)', 'Total (MW)'], mStatusData)}
                    </Grid>
                    <Grid item xs={12} xl={4}>
                      {renderDrilldownPieChart(mStatusData, 'markets', true)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>{'Volumes per Trading Strategy (MW)'}</CardHeader>
          <CardBody>
            {mStratData.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                  >
                    <Autocomplete
                    id="marketsStatus"
                    options={statusOptions}
                    value={selectedMarketsStatus}
                    getOptionLabel={(option) => option}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={(_, v) => setSelectedMarketsStatus(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                  >
                    <Autocomplete
                    id="marketsStratType"
                    options={typeOptions}
                    value={selectedMarketsType}
                    getOptionLabel={(option) => option}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField {...params} label="Type" />
                    )}
                    onChange={(_, v) => setSelectedMarketsType(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} xl={8}>
                      {renderTable(['Market', ...STRATEGIES, 'Total (MW)'], mStratData)}
                    </Grid>
                  <Grid item xs={12} xl={4}>
                      {renderDrilldownPieChart(mStratData, 'markets', false)}
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>{'Volumes per Client (MW)'}</CardHeader>
          <CardBody>
            {cStatusData.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                    className={classNames(
                      classes.formControl,
                      classes.fullWidth,
                      classes.marginBottom
                    )}
                  >
                    <Autocomplete
                      id="clientsType"
                      options={typeOptions}
                      value={clientsType}
                      getOptionLabel={(option) => option}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField {...params} label="Type" />
                      )}
                      onChange={(_, v) => setClientsType(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} xl={8}>
                      {renderTable(['Client', 'Live (MW)', 'Trial (MW)', 'Total (MW)'], cStatusData)}
                    </Grid>
                    <Grid item xs={12} xl={4}>
                      {renderDrilldownPieChart(cStatusData, 'clients', true)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>{'Volumes per Trading Strategy (MW)'}</CardHeader>
          <CardBody>
            {cStratData.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                  >
                    <Autocomplete
                    id="clientsStatus"
                    options={statusOptions}
                    value={selectedClientStatus}
                    getOptionLabel={(option) => option}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={(_, v) => setSelectedClientsStatus(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={2}>
                  <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                  >
                    <Autocomplete
                    id="clientsStratType"
                    options={typeOptions}
                    value={selectedClientsType}
                    getOptionLabel={(option) => option}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField {...params} label="Type" />
                    )}
                    onChange={(_, v) => setSelectedClientsType(v)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12} xl={8}>
                      {renderTable(['Client', ...STRATEGIES, 'Total (MW)'], cStratData)}
                    </Grid>
                  <Grid item xs={12} xl={4}>
                      {renderDrilldownPieChart(cStratData, 'clients', false)}
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <NoData />
            )}
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader color='primary'>API's Overview</CardHeader>
          <CardBody>
            {renderTable(overviewColumns, overviewTableData, true)}
          </CardBody>
        </Card>
      </Grid>
    </Grid>
  );
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.container}>
          {marketsData && clientsData ? content() : (<div className="loader" alt="Loading report..." />)}
        </div>
      </Grid>
    </Grid>
  );
};

const ConnectedWorldwideRenewables = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorldwideRenewables);
export default withStyles(helper.styles, tableStyle, styles)(ConnectedWorldwideRenewables);
