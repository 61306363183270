import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  TextField,
  Grid,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import withStyles from '@material-ui/core/styles/withStyles';
import alertify from 'alertifyjs';
import classNames from 'classnames';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomButton from 'components/CustomButtons/Button';
import ParkAutocomplete from 'components/Autocompletes/ParkAutocomplete';
import LoginPage from 'views/Login/Oops.jsx';
import { 
  security_fetch_params, 
  get_markets_data, 
} from 'actions/index';
import { logout } from 'utils/auth';
import * as helper from './AuditDataHelper';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    conn: state.conn,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_markets_data: (data) => dispatch(get_markets_data(data)),
});

const AuditAlertsDetails = ({ 
  classes, 
  isLoggedIn, 
  location,
  history,
  conn,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [alertData, setAlertData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [instances, setInstances] = useState([]);
  const [parks, setParks] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(null)
  const alertDataRef = useRef(null);

  const getInstancesData = async () => {
    const data = await helper.getAuditInstances();
    const loginInstances = Object.keys(conn.loginInstances).map(key => conn.loginInstances[key]);
    const filteredData = data
      .filter(el => loginInstances.find(instance => instance.target.replace('-', '_') === el.name))
      .map(el => ({...loginInstances.find(instance => instance.target.replace('-', '_') === el.name), id: el.id}));
    setInstances(filteredData);
  }
  
  const getAlertData = async () => {
    const response = await fetch(
      `https://kube.sama.energy/gateway/api/audit_alerts/${location.state.id}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        ...security_fetch_params,
      }
    );

    if (response.status === 401) {
      logout();
      return null;
    }

    if (response.ok) {
      const data = await response.json();
      const alertData = {
        park: data.park, 
        type: data.type, 
        stage: data.stage,
        cron: data.cron,
        timezone: data.timezone,
        enabled: data.enabled 
      }

      alertDataRef.current = JSON.stringify(alertData);
      setSelectedInstance(instances.find(el => el.target.replace('-', '_') === data.park.instance.name))
      setAlertData(alertData);
      setIsLoading(false);
    } else {
      alertify.error('Cannot get Audit Alert Data!', 5);
    }
  };

  const handleEvent = async (method, id, data) => {
    const eventSuccess = method === 'POST' 
      ? 'created' : method === 'PUT' 
        ? 'updated' : 'deleted';
    const eventError = method === 'POST' 
      ? 'creating' : method === 'PUT' 
        ? 'updating' : 'deleting';
    const response = await fetch(`https://kube.sama.energy/gateway/api/audit_alerts${id ? `/${id}` : ''}`, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
      ...security_fetch_params,
    });

    if (response.ok) {
      if (method === 'DELETE') {
        history.push('/audit_alerts');
        alertify.success(`Audit alert successfully deleted!`, 5);
        return
      }

      const data = await response.json();
      const alertData = {
        park: data.park, 
        type: data.type, 
        stage: data.stage,
        cron: data.cron,
        timezone: data.timezone,
        enabled: data.enabled 
      }
      alertDataRef.current = JSON.stringify(alertData);
      setSelectedInstance(instances.find(el => el.target.replace('-', '_') === data.park.instance.name))
      setAlertData(alertData)
      alertify.success(`Audit alert successfully ${eventSuccess}!`, 5);

      if (method === 'POST') history.push('/audit_alert_details', { id: data.id })
    } else {
      alertify.error(`Error ${eventError} audit alert!`, 5);
    }
  }
  
  const getParks = async (instance_id) => {
    const parks = await helper.getInstanceParks(instance_id);
    setParks(parks);
  }
  
  useEffect(() => {
    getInstancesData();
  }, [])

  useEffect(() => {
    if (instances.length) {
      if (location.state) {
        getAlertData();
      } else {
        const initial = {
          park: null,
          type: '',
          stage: null,
          cron: '',
          timezone: '',
          enabled: true,
        }
        alertDataRef.current = JSON.stringify(initial);
        setAlertData(initial);
        setIsLoading(false);
      }
    }
  }, [instances])

  useEffect(() => {
    if (selectedInstance) {
      getParks(selectedInstance.id)
    }
  }, [selectedInstance])

  useEffect(() => {
    if (alertData) {
      if (!isActive && 
        JSON.stringify(alertData) !== alertDataRef.current && 
        Object.entries(alertData).filter(
          (el) => el[0] !== 'stage' && el[0] !== 'enabled' && el[1]
        ).length === 4
      ) {
        setIsActive(true);
      } else if (isActive && 
        (JSON.stringify(alertData) === alertDataRef.current || 
        Object.entries(alertData).filter(
          (el) => el[0] !== 'stage' && el[0] !== 'enabled' && el[1]
        ).length !== 4)
      ) {
        setIsActive(false);
      }
    }
  }, [alertData])

  const handleChange = (field, value) => {
    setAlertData({...alertData, [field]: value});
  }

  const onSubmit = (type, id) => {
    const data = {...alertData, park_id: alertData.park.id};
    delete data.park;

    switch (type) {
      case 'update':
        handleEvent('PUT', id, data);
        break;
      case 'delete': 
        handleEvent('DELETE', id);
        break;
      default: 
        handleEvent('POST', null, data);
    }
  }

  if (isLoggedIn) {
    return isLoading ? (
      <div className="loader">Loading...</div>
    ) : (
      <Grid container spacing={2}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classNames(classes.cardTitleWhite)}>
              Audit Alert Details
            </h4>
          </CardHeader>
          <CardBody>
            <Grid container spacing={4} alignItems='center'>
              {location.state ? (
                <>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <FormControl
                      className={classNames(
                        classes.formControl,
                        classes.fullWidth,
                        classes.marginBottom
                      )}
                    >
                      <TextField 
                        type="text"
                        value={selectedInstance.API_NAME}
                        variant="standard"
                        label="Instance:"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <FormControl
                      className={classNames(
                        classes.formControl,
                        classes.fullWidth,
                        classes.marginBottom
                      )}
                    >
                      <TextField 
                        type="text"
                        value={alertData.park.park_name}
                        variant="standard"
                        label="Park:"
                        fullWidth={true}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <FormControl
                      className={classNames(
                        classes.formControl,
                        classes.fullWidth,
                        classes.marginBottom
                      )}
                    >
                      <ParkAutocomplete
                        id="instance"
                        label="Instance:"
                        options={instances}
                        value={selectedInstance}
                        getOptionLabel={(option) => option.API_NAME}
                        onChange={(_, v) => setSelectedInstance(v)}
                        variant="standard"
                        disableClearable={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4} xl={2}>
                    <FormControl
                      className={classNames(
                        classes.formControl,
                        classes.fullWidth,
                        classes.marginBottom
                      )}
                    >
                      <ParkAutocomplete
                        id="park"
                        label="Park:"
                        options={parks}
                        value={alertData.park}
                        getOptionLabel={(option) => option.park_name}
                        onChange={(_, v) => handleChange('park', v)}
                        variant="standard"
                        disableClearable={true}
                        disabled={!selectedInstance}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField 
                    type="text"
                    value={alertData.type}
                    onChange={(e) => handleChange('type', e.target.value)}
                    variant="standard"
                    label="Type:"
                    fullWidth={true}
                    InputProps={{
                      readOnly: location.state ? true : false
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField 
                    type="text"
                    value={alertData.stage || ''}
                    onChange={(e) => handleChange('stage', e.target.value)}
                    variant="standard"
                    label="Stage:"
                    fullWidth={true}
                    InputProps={{
                      readOnly: location.state ? true : false
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField 
                    type="text"
                    value={alertData.cron || ''}
                    onChange={(e) => handleChange('cron', e.target.value)}
                    variant="standard"
                    label="Cron:"
                    fullWidth={true}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField 
                    type="text"
                    value={alertData.timezone}
                    onChange={(e) => handleChange('timezone', e.target.value)}
                    variant="standard"
                    label="Timezone:"
                    fullWidth={true}
                    InputProps={{
                      readOnly: location.state ? true : false
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={2}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom,
                    classes.statusContainer
                  )}
                >
                  <Autocomplete 
                    className={classes.fullWidth}
                    value={alertData.enabled ? 'Enabled' : 'Disabled'} 
                    options={['Enabled', 'Disabled']}
                    disableClearable={true}
                    filterSelectedOptions={true}
                    renderInput={(params) => 
                      <TextField {...params} label="Status:" />
                    }
                    onChange={() => handleChange('enabled', !alertData.enabled)}
                  />
                </FormControl>
              </Grid>
              {location.state ? (
                <>
                  <Grid item xs={12} sm={3} lg={2} xl={1}>
                    <CustomButton 
                      className={classes.fullWidth} 
                      color='primary'
                      disabled={!isActive}
                      onClick={() => onSubmit('update', location.state.id)}
                    >
                      Save
                    </CustomButton>
                  </Grid>
                  <Grid item xs={12} sm={3} lg={2} xl={1}>
                    <CustomButton 
                      className={classes.fullWidth} 
                      color='primary' 
                      onClick={() => onSubmit('delete', location.state.id)}
                    >
                      Delete
                    </CustomButton>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={3} lg={2} xl={1}>
                  <CustomButton 
                    className={classes.fullWidth} 
                    color='primary'
                    disabled={!isActive}
                    onClick={() => onSubmit('create')}
                  >
                    Create
                  </CustomButton>
                </Grid>
              )}
              <Grid item xs={12} sm={3} lg={2} xl={1}>
                <CustomButton 
                  className={classes.fullWidth} 
                  color='primary'
                  onClick={() => history.push('/audit_alerts')}
                >
                  Cancel
                </CustomButton>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
    );
  } else {
    return <LoginPage />;
  }
};

const ConnectedAuditAlertsDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditAlertsDetails);
export default withStyles(helper.styles)(ConnectedAuditAlertsDetails);
