import React, { Component } from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
// core components
import Fundamentals from 'components/MarketsDashboard/Fundamentals';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoginPage from "views/Login/Oops.jsx";
import RegionPicker from "components/Table/RegionPicker";
import { get_fundamentals_audit, get_markets_data } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const mapStateToProps = state => {
  return {
    fundamentals: state.fundamentals.fundamentals,
    loading: state.fundamentals.loading,
    isLoggedIn: state.login.loggedIn,
    markets: state.markets.markets,
    curRegion: state.conn.curRegion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_fundamentals_audit: data => dispatch(get_fundamentals_audit(data)),
  get_markets_data: data => dispatch(get_markets_data(data)),
});

class MarketsList extends Component {
  componentDidMount() {
    this.props.get_fundamentals_audit();
    this.props.get_markets_data();
  }

  render() {
    const { markets, fundamentals, loading, isLoggedIn, curRegion } = this.props;
    const tableHead = ['ID', 'Name', 'Description', 'Country', 'TimeZone', 'Actions'];
    const tableData = [];

    if (isLoggedIn) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <RegionPicker markets={markets} helpModeActive={this.props.helpModeActive}/>
              {(fundamentals && markets.length) ?
                <CardBody >
                  <Fundamentals
                    tableHead={tableHead}
                    tableData={tableData}
                    fundamentals={fundamentals}
                    winHeight={window.innerHeight}
                    marketsCountries={markets}
                    activeRegion={curRegion}
                    helpModeActive={this.props.helpModeActive}
                  />
                </CardBody> : loading ?
                  <div className="loader" alt="Loading report..."/>
                  : null
              }
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return (<LoginPage />);
    }
  }
}

const ConnectedMarkets = connect(mapStateToProps, mapDispatchToProps)(MarketsList);
export default withStyles(dashboardStyle)(ConnectedMarkets);


