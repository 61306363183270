import React from "react";
import { connect } from "react-redux";
import { sprintf } from "sprintf-js";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import Button from 'components/CustomButtons/Button';
import {
  oneDimVolGran,
  oneDimDaPriceSamGran,
} from 'utils/calcFunctions';

const mapStateToProps = (state) => {
  return {
    tableHead: state.reports.plreport.tableHead,
  }
}

const DownloadDetailedReport = ({
  loading, 
  data, 
  filename, 
  tableHead, 
  stagesHeads, 
  stagesRows, 
  currency, 
  exchangeRates, 
  tooltip, 
  helpModeActive
}) => {
  const exportToExel = () => {
    const tableHeads = tableHead.map((row, i) => {
      const currencyRow = row.map(el => el.replace('currency', `${currency ? currency : 'currency'}`))
      if (i === 0) {
        return [
          'Date',
          ...currencyRow.slice(0, 1), 
          ...(stagesHeads[0] ? stagesHeads[0] : []),
          ...currencyRow.slice(1, 2),
          null, 
          null, 
          null, 
          null, 
          ...currencyRow.slice(2, 3), 
          null, 
          null,
          ...currencyRow.slice(3, 4),
          null, 
          null, 
          null,
          ...currencyRow.slice(4, 5),
          null,
          null,
          ...currencyRow.slice(5)
        ];
      } else {
        return [null, null, ...(stagesHeads[1] ? stagesHeads[1] : []), ...currencyRow];
      }
    });

    let daSamawattTotal = 0;
    let idSamawattTotal = 0;
    let ibSamawattTotal = 0;
    let totalSamawatt = 0;
    let daCustomerTotal = 0;
    let ibCustomerTotall = 0;
    let totalCustomer = 0;
    let addedValueTotal = 0;
    
    const reportData = data.map((el, index) => {
      const dailyExchangeRate = exchangeRates[el.date];
      daSamawattTotal += dailyExchangeRate ? el.da_samawatt / dailyExchangeRate : el.da_samawatt;
      idSamawattTotal += dailyExchangeRate ? el.id_samawatt / dailyExchangeRate : el.id_samawatt;
      ibSamawattTotal += dailyExchangeRate ? el.ib_samawatt / dailyExchangeRate : el.ib_samawatt;
      totalSamawatt += dailyExchangeRate ? el.total_samawatt / dailyExchangeRate : el.total_samawatt;
      daCustomerTotal += dailyExchangeRate ? el.da_customer / dailyExchangeRate : el.da_customer;
      ibCustomerTotall +=  dailyExchangeRate ? el.ib_customer / dailyExchangeRate :el.ib_customer;
      totalCustomer += dailyExchangeRate ? el.total_customer / dailyExchangeRate : el.total_customer;
      addedValueTotal += dailyExchangeRate ? el.added_value / dailyExchangeRate : el.added_value;

      const extra_data = JSON.parse(el.extra_data);
      const da_samawatt_detail = JSON.parse(el.da_samawatt_detail);
      const da_volumes_samawatt = JSON.parse(el.da_volumes_samawatt);
      const da_customer_detail = JSON.parse(el.da_customer_detail);
      const ib_samawatt_detail = JSON.parse(el.ib_samawatt_detail);
      const ib_volumes_samawatt = JSON.parse(el.ib_volumes_samawatt);
      const ib_customer_detail = JSON.parse(el.ib_customer_detail);
      const id_samawatt_detail = JSON.parse(el.id_samawatt_detail);
      const id_volumes_samawatt = JSON.parse(el.id_volumes_samawatt);
      const da_prices = extra_data.da_prices;
      const ib_prices_up = extra_data.ib_prices[1];
      const ib_prices_down = extra_data.ib_prices[0];
      const generations = el.generation;
      const base_volumes = el.base_volumes;
      const ib_dt = extra_data.parameters[2];
      const da_dt = extra_data.parameters[0];
      const id_dt = extra_data.parameters[1];

      const da_volumes_gran = da_volumes_samawatt.length && da_volumes_samawatt[0].length 
        ? oneDimVolGran(da_volumes_samawatt, da_dt, ib_dt, ib_prices_up.length * ib_dt)
        : Array((ib_prices_up.length * ib_dt) / Number(ib_dt)).fill(0);

      const da_prices_gran = da_prices.length && 
        da_prices[0].length && 
        da_volumes_samawatt.length && 
        da_volumes_samawatt[0].length
          ? oneDimDaPriceSamGran(da_volumes_samawatt, da_prices, da_dt, ib_dt, ib_prices_up.length * ib_dt)
          : Array((ib_prices_up.length * ib_dt) / Number(ib_dt)).fill(0);

      const da_samawatt_detail_gran = da_samawatt_detail.length && da_samawatt_detail[0].length
        ? oneDimVolGran(da_samawatt_detail, da_dt, ib_dt, ib_prices_up.length * ib_dt)
        : Array((ib_prices_up.length * ib_dt) / Number(ib_dt)).fill(0);
      
      const id_volumes_gran = id_volumes_samawatt && id_volumes_samawatt.length 
        ? oneDimVolGran(id_volumes_samawatt, id_dt, ib_dt, ib_prices_up.length * ib_dt)
        : null;
      
      const id_samawatt_detail_gran = id_samawatt_detail && id_samawatt_detail.length
        ? oneDimVolGran(id_samawatt_detail, id_dt, ib_dt, ib_prices_up.length * ib_dt)
        : null;
      
      const daibRatio = parseInt(generations.length / da_volumes_gran.length);

      let tableData = []

      for (let i = 0; i < da_volumes_gran.length; i++) {
        const hour = Math.floor(i * ib_dt);
        const minute = (i * ib_dt - Math.floor(i * ib_dt)) * 60;

        const id_volumes_samawatt = id_volumes_gran 
          ? id_volumes_gran[i].toFixed(3)
          : 0.0.toFixed(3);
        
        const id_samawatt_detail = id_samawatt_detail_gran
          ? id_samawatt_detail_gran[i]
          : 0.0;

        const convertedDaPrices = dailyExchangeRate ? da_prices_gran[i] / dailyExchangeRate : da_prices_gran[i];
        const convertedIbPriceUp = dailyExchangeRate ? (ib_prices_up[i] / parseFloat(daibRatio)) / dailyExchangeRate : ib_prices_up[i] / parseFloat(daibRatio);
        const convertedIbPriceDown = dailyExchangeRate ? (ib_prices_down[i] / parseFloat(daibRatio)) / dailyExchangeRate : ib_prices_down[i] / parseFloat(daibRatio);
        const convertedDaSamawattDetail = dailyExchangeRate ? da_samawatt_detail_gran[i] / dailyExchangeRate : da_samawatt_detail_gran[i]
        const convertedIdSamawattDetail = dailyExchangeRate ? id_samawatt_detail / dailyExchangeRate : id_samawatt_detail;
        const convertedIbSamawattDetail = dailyExchangeRate ? ib_samawatt_detail[i] / dailyExchangeRate : ib_samawatt_detail[i];
        const convertedDaCustomerDetail = dailyExchangeRate ? da_customer_detail[i] / dailyExchangeRate : da_customer_detail[i];
        const convertedIbCustomerDetail = dailyExchangeRate ? ib_customer_detail[i] / dailyExchangeRate : ib_customer_detail[i];
        const swTotal = convertedDaSamawattDetail + convertedIdSamawattDetail + convertedIbSamawattDetail;
        const cusTotal = convertedDaCustomerDetail + convertedIbCustomerDetail;

        let tableRow = [
          //Date
          el.date.replaceAll('-', '/'),
          //Hour
          sprintf('%02d:%02d', hour, minute),
          // Stages detail
          ...(stagesRows[index] ? stagesRows[index][i] : []),
          // Actual Production
          parseFloat(generations[i].toFixed(3)),
          // Day-Ahead forecast
          parseFloat(base_volumes[i].toFixed(3)),
          // Day-Ahead trades
          parseFloat(da_volumes_gran[i].toFixed(3)),
          // Intraday trades
          parseFloat(id_volumes_samawatt),
          // Imbalance
          parseFloat(ib_volumes_samawatt[i].toFixed(3)),
          //Da prices
          parseFloat(convertedDaPrices.toFixed(2)),
          // Up
          parseFloat(convertedIbPriceUp.toFixed(2)),
          // Down
          parseFloat(convertedIbPriceDown.toFixed(2)),
          // SW Day-ahead
          parseFloat(convertedDaSamawattDetail.toFixed(2)),
          // SW Intraday
          parseFloat(convertedIdSamawattDetail.toFixed(2)),
          // SW Imbalance
          parseFloat(convertedIbSamawattDetail.toFixed(2)),
          // SW Total
          parseFloat(swTotal.toFixed(2)),
          // BA Day-ahead
          parseFloat(convertedDaCustomerDetail.toFixed(2)),
          // BA Imbalance
          parseFloat(convertedIbCustomerDetail.toFixed(2)),
          // BA Total
          parseFloat(cusTotal.toFixed(2)),
          // Added Value
          parseFloat((
            convertedDaSamawattDetail +
            convertedIdSamawattDetail +
            convertedIbSamawattDetail - (
              convertedDaCustomerDetail + convertedIbCustomerDetail
            )
          ).toFixed(2)),
        ]

        tableData.push(tableRow);
      }

      return tableData
    }).flat()

    const reportDataWithTotal = [
      ...reportData, 
      [
        'Total',
        '',
        '',
        ...(stagesHeads[1] ? stagesHeads[1].map(() => '') : []),
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        // SW Day-ahead
        parseFloat(daSamawattTotal.toFixed(2)),
        // SW Intraday
        parseFloat(idSamawattTotal.toFixed(2)),
        // SW Imbalance
        parseFloat(ibSamawattTotal.toFixed(2)),
        // SW Total
        parseFloat(totalSamawatt.toFixed(2)),
        // BA Day-ahead
        parseFloat(daCustomerTotal.toFixed(2)),
        // BA Imbalance
        parseFloat(ibCustomerTotall.toFixed(2)),
        // BA Total
        parseFloat(totalCustomer.toFixed(2)),
        // Added Value
        parseFloat(addedValueTotal.toFixed(2)),
      ]
    ]

    // Create a new worksheet and add the table headers
    const ws = XLSX.utils.aoa_to_sheet(tableHeads);

    // Prepare table grid based on stages quantity
    // s = start, e = end
    let prevIndex = 0;
    let headsNulls = [];
    tableHeads[0].forEach((head, i) => {
      if (head) {
        headsNulls.push(0);
        prevIndex = headsNulls.length - 1;
        return
      }

      headsNulls[prevIndex] += 1;
      headsNulls.push(null);
    })

    let tableGrid = [];
    let prevColumn = 0;
    for(let i = 0; i < tableHeads[0].length; i++) {
      if (tableHeads[0][i]) {
        if (i === 0 || i === 1) {
          tableGrid.push({ s: {r: 0, c: i}, e: {r: 1, c: i + headsNulls[i]} });
          prevColumn = i + headsNulls[i];
          continue
        }

        tableGrid.push({ s: {r: 0, c: prevColumn + 1}, e: {r: 0, c: i + headsNulls[i]} });
        prevColumn = i + headsNulls[i];
      }
    }

    // Merge cells for the headers
    ws['!merges'] = [...tableGrid];

    // Add the report data to the worksheet
    XLSX.utils.sheet_add_aoa(ws, reportDataWithTotal, {origin: -1})

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    
    // Write the workbook to an array and save it as a file
    const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})

    // Export the file using the saveAs function
    saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `${filename}.xlsx`)
  }
  
  return (
    <>
      <Button
        color="primary"
        round
        onClick={() => exportToExel()}
        disabled={!(data?.length > 0) || loading}
        tooltip={tooltip}
        helpModeActive={helpModeActive}
      >
        Download Detailed
      </Button>
    </>
  )
}

export default connect(mapStateToProps)(DownloadDetailedReport);