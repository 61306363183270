import {
  FormControl,
  TextField,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CustomButton from 'components/CustomButtons/Button';
import LoginPage from 'views/Login/Oops';

import { DATE_FORMAT_DASH, DATE_FORMAT_DOTS } from 'constants/general';
import { useFrrData } from 'hooks/useFrrData';
import { useFrrChartData } from 'hooks/useFrrChartData';
import { getUnicProductsArr, getStyles, INITIAL_DATE_FROM, INITIAL_DATE_TO, MenuProps } from './FrrAnalysisHelper';

import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';
import alertify from 'alertifyjs';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';
import frrAnalysisStyle from 'assets/jss/material-dashboard-react/views/frrAnalysisStyle'

const mapStateToProps = (state) => ({
  isLoggedIn: state.login.loggedIn,
  apiLabel: state.conn.label
});


const FrrAnalysis = ({ classes, isLoggedIn, isLoading, name, address, contentClass, apiLabel }) => {
  const [products, setProducts] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const [dateFrom, setDateFrom] = useState(INITIAL_DATE_FROM);
  const [dateTo, setDateTo] = useState(INITIAL_DATE_TO);

  const [visibleLegends, setVisibleLegends] = useState(true);

  const [loading, frrData, prodLabelArr, getFrrData] = useFrrData(
    dateFrom,
    dateTo,
    address,
    apiLabel,
  );

  HighchartsExporting(Highcharts);
  HighchartsExportingData(Highcharts);
  Highcharts.removeEvent(Highcharts.Chart,'beforeShowResetZoom');
  
  const theme = useTheme();

  //create products array for select field
  const unicProducts = useMemo(
    () => getUnicProductsArr(prodLabelArr),
    // eslint-disable-next-line
    []
  );

  const frrChartData = useFrrChartData(products, selectedDates, visibleLegends, frrData, name);

  const handleChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      if (!value) return;
      const newDate = moment(value);
      if (
        name === 'dateFrom'
      ) {
        // check if data consist these date range
        if (
          !frrData[newDate.format(DATE_FORMAT_DOTS)] ||
          !frrData[newDate.format(DATE_FORMAT_DOTS)]?.['NEG_1'] ||
          !frrData[dateTo.format(DATE_FORMAT_DOTS)] ||
          !frrData[dateTo.format(DATE_FORMAT_DOTS)]?.['NEG_92']
        ) {
          alertify.error(`No Data. Push Button GET DATA`, 5);
        }
        setDateFrom(newDate);
        // dateTo can't be less than dateFrom
      } else if (
        name === 'dateTo'
      ) {
        // check if data consist these date range
        if (
          !frrData[dateFrom.format(DATE_FORMAT_DOTS)] ||
          !frrData[dateFrom.format(DATE_FORMAT_DOTS)]?.['NEG_1'] ||
          !frrData[newDate.format(DATE_FORMAT_DOTS)] ||
          !frrData[newDate.format(DATE_FORMAT_DOTS)]?.['NEG_92']
        ) {
          alertify.error(`No Data. Push Button GET DATA`, 5);
        }
        setDateTo(newDate);
      }
    },
    [dateFrom, setDateFrom, dateTo, setDateTo, frrData]
  );

  const handleChangeProd = (event) => {
    const {
      target: { value },
    } = event;
    setProducts(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeDate = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDates(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleGetData = ()=>{
    if(dateTo.startOf('day') > dateFrom.startOf('day')) 
      getFrrData()
    else
      alertify.error(`Date To to can't be less or equel than Date From`, 5);
  }

  const dateItems = Object.keys(frrData).map((date) => (
    <MenuItem
      key={date}
      value={date}
      style={getStyles(date, selectedDates, theme)}
    >
      {date}
    </MenuItem>
  ));

  const productItems = unicProducts.map((name) => (
    <MenuItem key={name} value={name} style={getStyles(name, products, theme)}>
      {name}
    </MenuItem>
  ));

  const loader = (
    <div className={classes.loadingContainer}>
      <div className="loader" alt="Loading report..."/>
    </div>
  );
  
  const content = !loading
    ? Object.keys(frrData).length !== 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card className={classNames(classes.bgColor, classes.noMargin)}>
              <CardBody className={classes.bgColorAlt}>
                <div
                  className={classes.container}
                  onDoubleClick={() => {
                    frrChartData.series.length &&
                      setVisibleLegends(!visibleLegends);
                  }}
                >
                  <HighchartsReact
                    highcharts={Highcharts}
                    constructorType={'stockChart'}
                    options={frrChartData}
                  />
                </div>
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      )
    : loader;

  if (!isLoggedIn) return <LoginPage />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classNames(classes.bgColor, classes.noMargin)}>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>
              German {name.toUpperCase()} anonymous bids (CET/CEST time zone)
            </h4>
          </CardHeader>
          <CardBody
            className={classNames(
              classes.bgColor,
              classes.sticky,
              classes.textLeft
            )}
          >
            <Grid container spacing={2} className={classes.flex}>
              <Grid item xs={6} sm={4} className={classes.flex}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateFrom.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateFrom',
                      id: 'dateFrom',
                    }}
                    label="From:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <TextField
                    type="date"
                    value={dateTo.format(DATE_FORMAT_DASH)}
                    onChange={handleChange}
                    inputProps={{
                      name: 'dateTo',
                      id: 'dateTo',
                    }}
                    label="To:"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <CustomButton 
                    color="primary" 
                    onClick={handleGetData} 
                    tooltip='Loads data for the specified time period selected in the "From" and "To" fields.'
                  >
                    Get data
                  </CustomButton>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <InputLabel id="date-label">Select date:</InputLabel>
                  <Select
                    labelId="date-label"
                    id="multiple-date-label"
                    multiple
                    value={selectedDates}
                    onChange={handleChangeDate}
                    MenuProps={MenuProps}
                  >
                    {dateItems}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.fullWidth,
                    classes.marginBottom
                  )}
                >
                  <InputLabel id="product-label">Select product:</InputLabel>
                  <Select
                    labelId="product-label"
                    id="multiple-product-label"
                    multiple
                    value={products}
                    onChange={handleChangeProd}
                    MenuProps={MenuProps}
                  >
                    {productItems}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {content && <div className={classNames(classes.container, contentClass)}>{content}</div>}
      </Grid>
    </Grid>
  );
};

const ConnectedFrrAnalysis = connect(mapStateToProps)(FrrAnalysis);
export default withStyles(frrAnalysisStyle)(ConnectedFrrAnalysis);
