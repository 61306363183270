// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import DataExplore from "@material-ui/icons/Explore";
import Schedule from "@material-ui/icons/Schedule";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import LocationOn from "@material-ui/icons/LocationOn";
import MultilineChart from "@material-ui/icons/MultilineChart";
import Notifications from "@material-ui/icons/Notifications";
import Settings from "@material-ui/icons/Settings";
import Store from "@material-ui/icons/Store";
import Locked from "@material-ui/icons/Lock";
import SettingsBackupRestore from "@material-ui/icons/SettingsBackupRestore";

// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import FrDataDashboardPage from "views/DataDashboard/FrDataDashboard";
import DeDataDashboardPage from "views/DataDashboard/DeDataDashboard";
import UkDataDashboardPage from "views/DataDashboard/UkDataDashboard";
import ItSouthDataDashboardPage from "views/DataDashboard/ItSouthDataDashboard";
import ItNorthDataDashboardPage from "views/DataDashboard/ItNorthDataDashboard";
import MarketsDataPage from "views/DataDashboard/MarketsData.jsx";
import FundamentalsDataPage from "views/Markets/Fundamentals.jsx";
import TradesDashboardPage from "views/TradesDashboard/TradesDashboard.jsx";
import ForecastPage from "views/Forecast/Forecast.jsx";
import ProfitLossReport from "views/Reports/ProfitLossReport.jsx";
import ProfitLossInterval from "views/Reports/ProfitLossInterval.jsx";
import Jobs from "views/Jobs/Jobs.jsx";
import JobLog from "views/Jobs/JobLog.jsx";
import OptimisationLog from "views/Optimisation/OptimisationLog.jsx";
import RTrader from "views/R-Trader/Main.jsx";
import Optimisation from "views/Optimisation/Optimisation.jsx";
import StructuredAlertsPage from "views/StructuredAlerts/StructuredAlerts.jsx";
import Config from "views/Config/Config.jsx";
import OverrideDA from "views/OverrideDA/OverrideDA.jsx";
import StopTradingPage from "views/StopTrading/StopTrading";

import ScheduleJobsList from "views/ScheduleJobs/ScheduleJobsList";
import ScheduleJobsDetails from "views/ScheduleJobs/ScheduleJobsDetails";

import TradingConnectorsList from "views/TradingConnectors/TradingConnectorsList";
import TradingConnectorsDetails from "views/TradingConnectors/TradingConnectorsDetails";

import Backtesting from "views/Backtesting/Backtesting.jsx";
import SessionResults from "views/Backtesting/SessionResults.jsx";

import NotificationsPage from "views/Notifications/Notifications.jsx";
import LoginPage from "views/Login/LoginForm.js";
import LoadingPage from "views/Loading/Loading.js";
import MetricsPage from "views/Markets/Metrics.jsx";
import Markets from "views/Markets/Markets.jsx";
import MarketData from "views/Markets/MarketData.jsx";
import FundamentalsAudit from "views/DataDashboard/FundamentalsData"
import MarketScores from "views/DataDashboard/MarketScores.jsx";

import PNLAnalysis from 'views/KPI/PNLAnalysis';
import Permissions from "views/Permissions/Permissions";

import DataAnalysis from 'views/DataAnalysis/DataAnalysis';
import DataAnalysisDetails from 'views/DataAnalysis/DataAnalysisDetails';
import TopStrategies from "views/KPI/Profit";
import AfrrAndMfrrAnonymousBids from "views/Markets/AfrrAndMfrrAnonymous.jsx";
import InstanceReport from "views/KPI/InstanceReport";
import AuditDashboard from "views/AuditData/AuditDashboard";
import AuditData from "views/AuditData/AuditData";
import AuditDataDetails from "views/AuditData/AuditDataDetails";
import ParksJobDetails from "views/KPI/ParksJobDetails";
import AuditDashboardSettings from "views/AuditData/AuditDashboardSettings";
import AuditAlerts from "views/AuditData/AuditAlerts";
import AuditAlertsDetails from "views/AuditData/AuditAlertsDetails";
import ParkCreation from "views/Creation/ParkCreation";
import WorldwideRenewables from "views/KPI/WorldwideRenewables";


export const dashboardRoutes = [
  {
    path: "/dashboard",
    sidebarName: "Dashboard",
    navbarName: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The Dashboard page serves as the landing page, providing an overview of the key parameters of the selected park and highlighting the most significant prices for the German and French markets."
  },
  {
    path: "/tradesdashboard",
    sidebarName: "Trades Dashboard",
    navbarName: "EPEX Trades",
    icon: "touch_app",
    component: TradesDashboardPage,
    hide: true,
  },
  {
    path: "/frdatadashboard",
    sidebarName: "French Dashboard",
    navbarName: "French Dashboard",
    icon: "view_module",
    component: FrDataDashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The French Dashboard page serves as a centralized monitoring dashboard, providing an up-to-date overview of data updates related to energy systems and market monitoring in the selected region. The primary purpose of the dashboard is to track the update status of key data over the past 24 hours and to identify potential issues, such as missing or outdated data."
  },
  {
    path: "/dedatadashboard",
    sidebarName: "German Dashboard",
    navbarName: "German Dashboard",
    icon: "view_module",
    component: DeDataDashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The German Dashboard page serves as a centralized monitoring dashboard, providing an up-to-date overview of data updates related to energy systems and market monitoring in the selected region. The primary purpose of the dashboard is to track the update status of key data over the past 24 hours and to identify potential issues, such as missing or outdated data."
  },
  {
    path: "/ukdatadashboard",
    sidebarName: "UK Dashboard",
    navbarName: "UK Dashboard",
    icon: "view_module",
    component: UkDataDashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The UK Dashboard page serves as a centralized monitoring dashboard, providing an up-to-date overview of data updates related to energy systems and market monitoring in the selected region. The primary purpose of the dashboard is to track the update status of key data over the past 24 hours and to identify potential issues, such as missing or outdated data."
  },
  {
    path: "/itsouthdatadashboard",
    sidebarName: "IT South Dashboard",
    navbarName: "IT South Dashboard",
    icon: "view_module",
    component: ItSouthDataDashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The IT South Dashboard page serves as a centralized monitoring dashboard, providing an up-to-date overview of data updates related to energy systems and market monitoring in the selected region. The primary purpose of the dashboard is to track the update status of key data over the past 24 hours and to identify potential issues, such as missing or outdated data."
  },
  {
    path: "/itnorthdatadashboard",
    sidebarName: "IT North Dashboard",
    navbarName: "IT North Dashboard",
    icon: "view_module",
    component: ItNorthDataDashboardPage,
    hide: false,
    group: 'Data integrity',
    description: "The IT North Dashboard page serves as a centralized monitoring dashboard, providing an up-to-date overview of data updates related to energy systems and market monitoring in the selected region. The primary purpose of the dashboard is to track the update status of key data over the past 24 hours and to identify potential issues, such as missing or outdated data."
  },
  {
    path: "/auditdata",
    sidebarName: "Markets Dashboard",
    navbarName: "Markets Dashboard",
    icon: DataExplore,
    component: MarketsDataPage,
    hide: false,
    group: 'Data integrity',
    description: "The Markets Dashboard page is a monitoring tool designed to analyze the quality of data feeds across various markets and regions, enabling users to track data parameters in real-time and quickly identify issues related to data completeness and freshness. The numbers in the table represent scores based on data completeness (no gaps in the last 24 hours) and data freshness."
  },
  {
    path: "/markets",
    sidebarName: "All Markets",
    navbarName: "All Markets",
    icon: LocationOn,
    component: Markets,
    hide: false,
    group: 'Market Data',
    description: 'The All Markets page provides a convenient interface for accessing the list of all markets and their key characteristics, such as name, description, country, and time zone. The "Actions" button in each row of the table provides a direct link to the Market Data page, where users can access and manage all variables related to the selected market.'
  },
  {
    path: "/fundamentals",
    sidebarName: "Forecast Data",
    navbarName: "Forecast Data",
    icon: Store,
    component: FundamentalsDataPage,
    hide: false,
    group: 'Market Data',
    description: 'The Forecast Data page is designed to monitor received forecasts for each variable and market, providing tools to visualize and compare forecast data. Its purpose is to ensure that forecasts are available for all necessary variables and markets, avoiding data gaps, and to assess their timeliness using the Update Hour timestamp, which is particularly important for short-term analysis.'
  },
  {
    path: "/rtrader",
    sidebarName: "Live EPEX Data",
    navbarName: "Live EPEX Data",
    icon: TransferWithinAStation,
    component: RTrader,
    hide: false,
    group: 'Market Data',
    description: 'The Live EPEX Data page provides real-time information on intraday trading data from the EPEX (European Power Exchange) platform. It allows users to monitor the latest trades and order books for selected countries and delivery hours. The page helps analyze intraday market trends, trading volumes, and price fluctuations in real time.' 
  },
  {
    path: "/profitlossdaily",
    sidebarName: "Profit and Loss Daily",
    navbarName: "Profit and Loss Daily",
    icon: "content_paste",
    component: ProfitLossReport,
    hide: true,
    parent: "/profitlossperiod",
    description: 'The P&L Daily page is designed for detailed analysis of profit and loss (P&L) for a selected day, providing data on volumes, prices, and P&L for each time interval set using the granularity parameter. It helps users evaluate trading performance, analyze forecasts, imbalances, and market prices, and identify opportunities for strategy optimization. Accurate data supports decision-making and provides deeper insights into the factors influencing trading results.'
  },
  {
    path: "/data_analysis",
    sidebarName: "Data Analysis",
    navbarName: "Data Analysis",
    icon: BubbleChart,
    component: DataAnalysis,
    hide: false,
    group: 'Market Data',
    description: 'The Data Analysis page provides users with access to the complete list of variables for the selected market. It is presented as a table displaying variable names, the type of available data, and data sources. The functionality of the page allows users to select various variables, including those from different markets, for more in-depth analysis.'
  },
  {
    path: "/data_analysis_details",
    sidebarName: "Data Analysis Details",
    navbarName: "Data Analysis Details",
    icon: BubbleChart,
    component: DataAnalysisDetails,
    hide: true,
    parent: "/data_analysis",
    description: 'The Data Analysis Details page is an interface for comparing and analyzing selected variables, considering the chosen data types and sources. It is designed for in-depth data exploration, trend visualization over time, and assessment of data accuracy and reliability. Users can compare variable values from various sources and evaluate the quality of forecast models using metrics such as the Mean Absolute Percentage Error (MAPE).'
  },
  {
    path: "/metrics",
    sidebarName: "Imb Price Analysis",
    navbarName: "Imbalance Price Metrics",
    icon: MultilineChart,
    component: MetricsPage,
    hide: false,
    group: 'Market Data',
    description: 'The Imbalance Price Analysis page is designed for studying electricity market prices and provides tools for analyzing their behavior, assessing forecast accuracy, and identifying market anomalies (price spikes). Key objectives include analyzing price dynamics using various data types, comparing forecasted (Forecast) and reconstructed (Backcast) data to assess discrepancies, identifying price spikes indicating market disruptions or extreme events, and providing recommendations for optimizing electricity trading and resource management.'
  },
  {
    path: "/afrr&mfrrbids",
    sidebarName: "aFRR and mFRR bids",
    navbarName: "aFRR and mFRR anonymous bids",
    icon: MultilineChart,
    component: AfrrAndMfrrAnonymousBids,
    hide: false,
    group: 'Market Data',
    description: 'The aFRR and mFRR Anonymous Bids page is designed for analyzing bids in the German markets for Automatic (aFRR) and Manual (mFRR) Frequency Restoration Reserve. It allows users to select a time range and products and visualize the data in graphs that display bid prices (EUR/MWh) based on allocated volume (MW). The page helps traders, analysts, and grid operators identify market trends, assess volume distribution, and make informed decisions.'
  },
  {
    path: "/profitlossperiod",
    sidebarName: "P&L Period",
    navbarName: "P&L Period",
    icon: "euro_symbol",
    component: ProfitLossInterval,
    hide: false,
    group: 'Profitability analysis',
    description: "The P&L Period page is designed for analyzing profit and loss (P&L) over a selected period and provides detailed insights into financial metrics and trading results. It allows users to evaluate cumulative and daily performance metrics, analyze results by days and hours, and compare base values (used as benchmarks) with optimised results (obtained through optimisation) to assess the added value and effectiveness of trading strategies."
  },
  {
    path: "/pnl_analysis",
    sidebarName: "P&L Analysis",
    navbarName: "P&L Analysis",
    icon: BubbleChart,
    component: PNLAnalysis,
    hide: false,
    group: 'Profitability analysis',
    description: 'The P&L Analysis page is designed for detailed analysis of profits and losses across various strategies, markets, and time periods. It provides users with the ability to evaluate strategy performance, analyze financial results, and compare them across different metrics.'
  },
  {
    path: "/instance_report",
    sidebarName: "Instance Report",
    navbarName: "Instance Report",
    icon: BubbleChart,
    component: InstanceReport,
    hide: false,
    group: 'Profitability analysis',
    description: 'The Instance Report page is designed for monitoring, analyzing, and comparing the financial performance of energy systems, parks, markets, and strategies, providing users with an overview of profit and loss (PnL) metrics at various levels of aggregation. It enables users to track and analyze PnL for different entities, compare financial results over various time periods (daily, month-to-date, year-to-date), and identify trends and gain insights into financial performance through visualized data.'
  },
  {
    path: "/worldwide_renewables",
    sidebarName: "Worldwide Renewables",
    navbarName: "Worldwide Renewables",
    icon: BubbleChart,
    component: WorldwideRenewables,
    hide: false,
    group: "Profitability analysis",
    description: ""
  },
  {
    path: "/parks_job_details",
    sidebarName: "Parks Job Details",
    navbarName: "Parks Job Details",
    icon: BubbleChart,
    component: ParksJobDetails,
    hide: false,
    group: 'Profitability analysis',
    description: 'The Parks Job Details page is designed for convenient comparison of park limits and models, allowing users to quickly analyze data and identify differences between parks across various markets and instances.'
  },
  {
    path: "/topstrategies",
    sidebarName: "Top strategies",
    navbarName: "Top strategies",
    icon: BubbleChart,
    component: TopStrategies,
    hide: false,
    group: 'Profitability analysis',
    description: 'The Top Strategies page is designed for analyzing and comparing the performance of various trading strategies, allowing users to evaluate profitability, accuracy, and risks over a selected period. It includes interactive features for in-depth data analysis, such as detailed key performance indicators (KPIs) and graphical analysis of strategies. Users can focus on the most profitable parks and prioritize them based on different metrics, enabling effective evaluation and optimization of trading approaches.'
  },
  {
    path: "/forecast",
    sidebarName: "Production Forecast",
    navbarName: "Production Forecast",
    icon: Schedule,
    component: ForecastPage,
    hide: false,
    group: 'Renewable Park',
    description: 'The Production Forecast page provides an overview and analysis of energy production and forecasts for energy parks. It is designed to monitor and analyze energy production efficiency by comparing actual production data with forecasts and capacity metrics.'
  },
  {
    path: "/optimisation",
    sidebarName: "Park Info",
    navbarName: "Park Info",
    icon: BubbleChart,
    component: Optimisation,
    hide: false,
    group: 'Renewable Park',
    description: 'The Park Info page is designed to display optimizations related to the selected energy park. Its purpose is to provide users with a detailed overview of all optimization entries and their associated profitability metrics.'
  },
  {
    path: "/config",
    sidebarName: "Park Config",
    navbarName: "Park Config",
    icon: BubbleChart,
    component: Config,
    hide: false,
    group: 'Renewable Park',
    description: 'The Park Config page is designed for configuring and managing the key parameters of an energy park, including nomination limits, production constraints, optimization schedules, and nomination submission timelines, as well as intraday trading timeframes. It allows users to select optimization modes, manage open position curves, and adjust other operational settings.'
  },
  {
    path: "/creation",
    sidebarName: "Park Creation",
    navbarName: "Park Creation",
    icon: BubbleChart,
    component: ParkCreation,
    hide: false,
    group: 'Renewable Park',
    description: 'The Park Creation page is designed for registering new energy parks in the system.'
  },
  {
    path: "/creation",
    sidebarName: "Park Creation",
    navbarName: "Park Creation",
    icon: BubbleChart,
    component: ParkCreation,
    hide: false,
    group: 'Renewable Park',
  },
  {
    path: "/overrideda",
    sidebarName: "Override DA",
    navbarName: "Override DA",
    icon: BubbleChart,
    component: OverrideDA,
    hide: false,
    group: 'Renewable Park',
    description: 'The Override DA page is designed for adjusting "Day Ahead" (DA) trade signals by uploading, viewing, and editing data through file imports. This functionality allows users to quickly adapt to changing market conditions.'
  },
  {
    path: "/tradeconn-details",
    sidebarName: "Trading Connectors Details",
    navbarName: "Trading Connectors Details",
    icon: Notifications,
    component: TradingConnectorsDetails,
    hide: true,
    description: 'The Trading Connectors Details page is designed for creating or modifying trading connectors that enable the transmission of nomination data through various communication channels. It allows users to specify transmission parameters such as recipients, servers, and email templates, create or edit file templates for sending data, and test the functionality of connectors before use.'
  },
  {
    path: "/tradingconnectors",
    sidebarName: "Trading Connectors",
    navbarName: "Trading Connectors",
    icon: Notifications,
    component: TradingConnectorsList,
    hide: false,
    group: 'Renewable Park',
    description: 'The Trading Connectors page is designed for managing trading connectors associated with an energy park selected through a dropdown selector. It allows users to select an energy park, create new trading connectors for configuring nomination data transmission, edit the parameters of existing connectors for proper integration with external systems, and delete unnecessary connectors to ensure efficient management of data transmission to markets via various channels.'
  },
  {
    path: "/schedulejobs",
    sidebarName: "Schedule Jobs",
    navbarName: "Schedule Jobs",
    icon: Notifications,
    component: ScheduleJobsList,
    hide: false,
    group: 'Renewable Park',
    description: 'The Schedule Jobs page is designed for managing scheduled tasks (jobs) that automate the execution of key operations in an energy park, including nomination management, optimization, data monitoring, and forecasting. It allows users to create, edit, and delete tasks, configure their schedules for timely execution, and select from a list of available operations, such as confirmation checks, nomination submissions, trading limit retrieval, or turbine signal generation.'
  },
  {
    path: "/schedjob-details",
    sidebarName: "Schedule Jobs Details",
    navbarName: "Schedule Jobs Details",
    icon: Notifications,
    component: ScheduleJobsDetails,
    hide: true,
    description: 'The Schedule Jobs Details page is designed for creating and configuring scheduled tasks (jobs) in an energy park. Users can specify operations to be performed, set schedules using CRON formats, define additional parameters (kwargs), and edit existing tasks to adjust their schedules or parameters. This ensures efficient automation of critical operations.'
  },
  {
    path: "/backtesting",
    sidebarName: "Backtesting",
    navbarName: "Backtesting",
    icon: SettingsBackupRestore,
    component: Backtesting,
    hide: false,
    group: 'Renewable Park',
    description: 'The Backtesting page is designed for managing the backtesting process, enabling users to simulate and analyze historical data to evaluate the effectiveness of strategies and processes in the energy park. It allows users to create new backtesting sessions for a specified time period, view the list of existing sessions with detailed information, and manage them by running, editing parameters, or deleting unnecessary sessions.'
  },
  {
    path: "/battery_config",
    sidebarName: "Battery Configuration",
    navbarName: "Battery Configuration",
    icon: BubbleChart,
    component: Config,
    hide: false,
    group: 'Battery Optimization',
    description: 'The Battery Configuration page is designed for configuring and managing the operational parameters of battery-based energy storage systems in the energy park. It allows users to define key specifications, optimization settings, and operational schedules to maximize battery efficiency and adapt to varying energy needs.'
  },
  {
    path: "/battery_trading_report",
    sidebarName: "Battery Trading Report",
    navbarName: "Battery Trading Report",
    icon: "euro_symbol",
    component: ProfitLossInterval,
    hide: false,
    group: "Battery Optimization",
    description: 'The Battery Trading Report page is designed for analyzing the profit and loss (P&L) of battery-based parks over a selected period and provides detailed information on financial metrics and trading results. It allows users to evaluate cumulative and daily performance metrics, analyze results by days and hours, and compare base values (used as benchmarks) with optimized results (obtained through optimization) to assess the added value and effectiveness of trading strategies.'
  },
  {
    path: "/battery_trading_analysis",
    sidebarName: "Battery Trading Analysis",
    navbarName: "Battery Trading Analysis",
    icon: BubbleChart,
    component: PNLAnalysis,
    hide: false,
    group: "Battery Optimization",
    description: 'The Battery Trading Analysis page is designed for detailed analysis of profits and losses across various strategies, markets, and time periods. It provides users with the ability to evaluate strategy performance, analyze financial results, and compare them across different metrics.'
  },
  {
    path: "/jobs",
    sidebarName: "Jobs",
    navbarName: "Jobs",
    icon: LibraryBooks,
    component: Jobs,
    hide: false,
    group: 'System monitoring',
    description: 'The Jobs List page is designed for monitoring and managing tasks in the system, providing users with detailed information about task statuses, potential issues, and logs. It allows users to track the status of tasks, their execution and completion times, identify errors, warnings, and crashes, and offers quick access to logs for diagnosing and analyzing the causes of failures, ensuring efficient management and resolution of issues.'
  },
  {
    path: "/notifications",
    sidebarName: "Notifications",
    navbarName: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/audit_dashboard",
    sidebarName: "Customer Delivery Dashboard",
    navbarName: "Customer Delivery Dashboard",
    icon: Notifications,
    component: AuditDashboard,
    hide: false,
    group: 'System monitoring',
    description: 'The Customer Delivery Dashboard monitors the status of deliveries to users, ensuring the tracking of key tasks such as turbine signal generation, production forecasting, and nomination delivery. It provides a centralized overview of operational statuses for various energy facilities and dates, helping to quickly identify delays or errors, verify the successful completion of operations, and take corrective actions to maintain efficiency and ensure timely delivery.'
  },
  {
    path: "/auditmarketdata",
    sidebarName: "Market Data Audit",
    navbarName: "Market Data Audit",
    icon: Notifications,
    component: AuditData,
    hide: false,
    group: 'System monitoring',
    description: 'The Market Data Audit page is designed for monitoring and analyzing the accuracy and quality of markets data. It allows users to audit data for selected variables, time intervals, and markets, identifying discrepancies or issues. This page helps ensure the reliability of data used in operations and trading strategies.'
  },
  {
    path: "/auditmarketdatadetails",
    sidebarName: "Audit Market Data Details",
    navbarName: "Audit Market Data Details",
    icon: Notifications,
    component: AuditDataDetails,
    hide: true,
    description: 'The Audit Market Data Details page is designed to provide detailed information about the results of market data audits, allowing users to analyze issues for specific time periods identified during the audit process, visualize them, and mark entries as acknowledged. It displays data collected during the audit with time-based details, enables automatic audit list refresh every 5 seconds when the "Run Audit" button is clicked, and visualizes problematic data for easier analysis.'
  },
  {
    path: "/login",
    sidebarName: "Login",
    navbarName: "Login",
    icon: Locked,
    component: LoginPage,
    hide: true
  },
  {
    path: "/loading",
    sidebarName: "Loading",
    navbarName: "Loading",
    icon: Locked,
    component: LoadingPage,
    hide: true
  },
  {
    path: "/job_log",
    sidebarName: "Job Log",
    navbarName: "Job Log",
    icon: Notifications,
    component: JobLog,
    hide: true,
    parent: "/jobs",
    description: 'The Job Log page is designed to display detailed information about the execution process of a specific task, allowing users to review the sequence of events, warnings, errors, and other related information. It simplifies diagnostics and troubleshooting by providing detailed task execution logs, helps identify the causes of errors or failures, and shows the sequence of system actions, contributing to a better understanding of processes and identification of problem areas.'
  },
  {
    path: "/optimisation_log",
    sidebarName: "Optimisation Log",
    navbarName: "Optimisation Log",
    icon: Notifications,
    component: OptimisationLog,
    hide: true,
    parent: "/optimisation",
    description: 'The Optimisation Log page is essential for analyzing and monitoring the efficiency of optimization processes in energy production and trading. It allows users to evaluate optimization results, compare nominations and confirmations in the day-ahead (DA) market, track imbalances (IMB), monitor price trends in DA and IMB markets, and analyze their impact on profitability. With data visualization and interactive charts, users can identify discrepancies, make informed decisions, and adjust strategies to improve profitability and reduce risks.'
  },
  {
    path: "/market_variables",
    sidebarName: "Market Data",
    navbarName: "Market Data",
    icon: Notifications,
    component: MarketData,
    hide: true,
    parent: "/markets",
    description: "The Market Data page allows users to analyze multiple variables for a selected market in detail. It includes the ability to use dual Y-axes for comparing variables with different scales and supports dynamic time range adjustments."
  },
  {
    path: "/session_results",
    sidebarName: "Session Results",
    navbarName: "Session Results",
    icon: Notifications,
    component: SessionResults,
    hide: true,
    parent: "/backtesting"
  },
  {
    path: "/marketaudit",
    sidebarName: "MarketScores",
    navbarName: "MarketScores",
    icon: Notifications,
    component: MarketScores,
    hide: true,
    parent: "/auditdata",
    description: "The Market Scores page is a tool for monitoring the quality and availability of data for various variables in the selected market and source. It includes the analysis of parameters such as completeness, timeliness, delays, and other metrics to ensure data accuracy and identify potential issues."
  },
  {
    path: "/fundamentalsaudit",
    sidebarName: "Fundamentals Audit",
    navbarName: "Fundamentals Audit",
    icon: Notifications,
    component: FundamentalsAudit,
    hide: true,
    parent: "/fundamentals",
    description: 'The Fundamentals Audit page is designed for in-depth analysis and verification of forecasted and actual data related to energy metrics. It allows users to compare forecasted data with actual values, assess the accuracy of models and forecast sources using metrics such as Mean Absolute Percentage Error (MAPE), and analyze historical and forecast trends. These features help identify deviations, evaluate forecast accuracy, and gain insights to support better decision-making.'
  },
  {
    path: "/structuredalerts",
    sidebarName: "Structured Alerts",
    navbarName: "Structured Alerts",
    icon: Notifications,
    component: StructuredAlertsPage,
    hide: false,
    group: 'System monitoring',
  },
  {
    path: "/stoptrading",
    sidebarName: "ID Trading Status",
    navbarName: "ID Trading Status",
    icon: Settings,
    component: StopTradingPage,
    hide: false,
    group: 'System monitoring',
    description: 'The ID Trading Status page is an interface for monitoring and managing the status of ID trading operations, if they are available. It enables operators to track the current state of trades and manually intervene to stop or start the process, if necessary.'
  },
  {
    path: "/permissions",
    sidebarName: "Sidebar settings",
    navbarName: "Sidebar settings",
    icon: Settings,
    component: Permissions,
    hide: false,
    group: 'Settings',
    description: 'The Sidebar settings page is designed for configuring the visibility of sidebar elements by enabling or disabling the corresponding checkboxes, allowing users to manage section availability and tailor the sidebar to their needs.'
  },
  {
    path: "/audit_settings",
    sidebarName: "Audit settings",
    navbarName: "Audit Dashboard settings",
    icon: Settings,
    component: AuditDashboardSettings,
    hide: false,
    group: 'Settings',
    description: 'The Audit Dashboard Settings page is designed to manage and configure audit parameters for instances and their associated parks, providing a centralized interface where users can enable or disable audit functions for specific instances and parks, as well as view and adjust configurations through expandable settings.'
  },
  {
    path: "/audit_alerts",
    sidebarName: "Audit Alerts",
    navbarName: "Audit Alerts",
    icon: Notifications,
    component: AuditAlerts,
    hide: false,
    group: 'Settings',
    description: 'The Audit Alerts page provides an overview and management tools for audit alerts, serving as a centralized dashboard where users can create, filter, and configure alerts to stay informed about critical events and respond promptly.'
  },
  {
    path: "/audit_alert_details",
    sidebarName: "Audit Alert Details",
    navbarName: "Audit Alert Details",
    icon: Notifications,
    component: AuditAlertsDetails,
    hide: true,
    parent: '/audit_alerts',
    description: 'The Audit Alert Details Page is designed for creating, viewing, and managing the configuration of specific audit alerts, providing detailed information about a selected alert and allowing users to modify or delete it. It enables users to review current settings, enable or disable the alert, and update scheduling, ensuring effective management of individual audit alerts.'
  },
  { redirect: true, path: "/", navbarName: "Redirect" }
];

export const filterRoutes = (conn) => {
  // Show Instance Report && Customer Delivery Dashboard only for permited instances
  if (!conn.showInstanceReport && !conn.showAuditDashboard && !conn.showCreation) {
    return dashboardRoutes.filter((route) => {
      return route.sidebarName !== 'Instance Report' && 
        route.sidebarName !== 'Worldwide Renewables' &&
        route.sidebarName !== 'Parks Job Details' && 
        route.sidebarName !== 'Customer Delivery Dashboard' &&
        route.sidebarName !== 'Audit settings' &&
        route.sidebarName !== 'Audit Alerts' &&
        route.sidebarName !== 'Audit Alert Details' &&
        route.sidebarName !== 'Park Creation'
    })
  } else if(!conn.showInstanceReport && !conn.showAuditDashboard) {
    // Show Instance Report only for permited instances
    return dashboardRoutes.filter((route) => {
      return route.sidebarName !== 'Instance Report' && 
      route.sidebarName !== 'Worldwide Renewables' &&
      route.sidebarName !== 'Parks Job Details' && 
      route.sidebarName !== 'Customer Delivery Dashboard' &&
      route.sidebarName !== 'Audit settings' &&
      route.sidebarName !== 'Audit Alerts' &&
      route.sidebarName !== 'Audit Alert Details'
    })
  } else if(!conn.showInstanceReport) {
    // Show Instance Report only for permited instances
    return dashboardRoutes.filter((route) => {
      return route.sidebarName !== 'Instance Report' && 
      route.sidebarName !== 'Worldwide Renewables' && 
      route.sidebarName !== 'Parks Job Details';
    })
  } else {
    return dashboardRoutes;
  }
};
