import React, { Component } from 'react';

import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { HelpOutline } from '@material-ui/icons';

import { Link } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import PropTypes from 'prop-types';
import { primaryColor } from '../../assets/jss/material-dashboard-react';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

import 'moment-timezone';

class FundamentalsVariables extends Component {
  render() {
    const { marketsCountries, activeRegion, classes, winHeight, helpModeActive } =
      this.props;
    let fundamentals = {...this.props.fundamentals};
    const variables = {
      'consumption_forecast_ensemble': 'Consumption forecast ENS',
      'consumption_production_forecast_ratio': 'Ratio consumption over renew prod forecast',
      'da_price_forecast': 'DA price forecast',
      'da_price_forecast_ensemble': 'DA price forecast ENS',
      'production_solar_forecast_ensemble': 'Solar forecast ENS',
      'production_wind_forecast_ensemble': 'Wind forecast ENS',
      'temperature_forecast_ensemble': 'Temperature forecast ENS',
      'consumption_divided_capacity' : 'Consumption divided capacity'
    }

    // Name of the row with all timestamps for forecasts curves
    const allIssueTimestampsName = 'All';

    //Calculate number of data point of consumption_production_forecast_ratio and add it to fundamentals
    fundamentals['consumption_production_forecast_ratio'] = {};
    fundamentals["consumption_divided_capacity"]={}
    Object.keys(fundamentals['consumption_forecast_ensemble']).forEach((keyTimePeriod) => {
        if(fundamentals['production_solar_forecast_ensemble']?.[keyTimePeriod] || fundamentals['production_wind_forecast_ensemble']?.[keyTimePeriod]) {
          fundamentals['consumption_production_forecast_ratio'][keyTimePeriod] = {};
          Object.keys(fundamentals['consumption_forecast_ensemble'][keyTimePeriod]).forEach((keyCountry) => {
            if(fundamentals['production_solar_forecast_ensemble']?.[keyTimePeriod]?.[keyCountry] || fundamentals['production_wind_forecast_ensemble']?.[keyTimePeriod]?.[keyCountry]) {
              fundamentals['consumption_production_forecast_ratio'][keyTimePeriod][keyCountry] = '+';
            }
          });
        }
        fundamentals["consumption_divided_capacity"][keyTimePeriod] = {};
        Object.keys(fundamentals['consumption_forecast_ensemble'][keyTimePeriod]).forEach((keyCountry) => {
          if(fundamentals['consumption_forecast_ensemble'][keyTimePeriod][keyCountry]) {
            fundamentals["consumption_divided_capacity"][keyTimePeriod][keyCountry] = '+'
          }
        })
    });
    Object.keys(fundamentals).forEach((key) => {
      fundamentals[key][allIssueTimestampsName] = {};
      Object.keys(fundamentals[key]).forEach(keyTimePeriod => {
        Object.keys(fundamentals[key][keyTimePeriod]).forEach(keyCountry => {
          if(fundamentals[key][keyTimePeriod][keyCountry]) {
            fundamentals[key][allIssueTimestampsName][keyCountry] = '+';
          }
        })
      })
    })

    if (fundamentals === null) {
      return null;
    }

    const notNullFundKeys = Object.keys(fundamentals).filter(
      (key) => fundamentals[key]
    );

    let tmp = {};

    Object.keys(fundamentals).forEach((varname) => {
      fundamentals[varname] &&
        Object.keys(fundamentals[varname]).forEach((dt) => {
          Object.keys(fundamentals[varname][dt]).forEach((country) => {
            tmp[country] = null;
          });
        });
    });

    let markets = Object.keys(tmp);
    markets.sort();

    // Place DE and FR at the start
    const posFr = markets.indexOf('FR');
    markets.splice(posFr, 1);
    const posDe = markets.indexOf('DE');
    markets.splice(posDe, 1);
    markets.unshift('FR');
    markets.unshift('DE');

    // Classification of the markets by region
    const marketsRegion = markets.map((market) => {
      const region = marketsCountries.find(
        (el) => el.country_iso3166a2 === market
      );
      return {
        country_iso3166a2: market,
        name: region.name,
        region:
          region?.parents.length === 2
            ? region?.parents[1][1]
            : region?.parents[0][1],
      };
    });

    const columns = [
      {
        title: 'Variable',
        field: 'variable',
        width: 150,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
        headerStyle: {
          left: '0px',
          zIndex: 3,
        },
      },
      {
        title: (
          <div className={classes.flexContainer}>
            <span>{'Update Hour'}</span>
            {helpModeActive && (
              <CustomTooltip title='Displays the issue time of the forecast.'>
                <HelpOutline fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        filed: 'update_hour',
        width: 171,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 150,
          zIndex: 1,
        },
        headerStyle: {
          left: 150,
          zIndex: 3,
        },
      },
    ];

    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      color: primaryColor,
      fontSize: '1em',
      textAlign: 'center',
      fontWeight: 500,
      padding: '12px 0',
    };
    
    // Only markets of the selected region
    const filterMarkets =
      activeRegion !== 'ALL'
        ? marketsRegion.filter(
            (market) => market.region.toUpperCase() === activeRegion
          )
        : marketsRegion;

    // Add columns for each market
    filterMarkets.forEach((market) => {
      columns.push({
        title: market.name,
        field: market.country_iso3166a2,
        width: 80,
        sorting: false,
      });
    });

    const fundamentalsArr = [];
    notNullFundKeys.forEach((key, index) => {
      const hourArr = Object.keys(fundamentals[key]).map((hour, hourKey) => {
        let marketsVal = {};
        filterMarkets.map((market) => {
          if (fundamentals[key][hour][market.country_iso3166a2] === undefined) {
            return (marketsVal = { ...marketsVal, [market.country_iso3166a2]: '' });
          }
          marketsVal = {
            ...marketsVal,
            [market.country_iso3166a2]: fundamentals[key][hour][market.country_iso3166a2],
          };
          return marketsVal;
        });
        if (hourKey === 0)
          return {
            id: `${index}_${hourKey}`,
            variable: variables[key],
            hour: hour,
            index: index,
            variableForLink: key,
            variableName: variables[key],
            ...marketsVal,
          };
        else 
          return {
            id: `${index}_${hourKey}`,
            hour: hour,
            index: index,
            variableForLink: key,
            variableName: variables[key],
            ...marketsVal
          };
      });
      fundamentalsArr.push([...hourArr]);
    });
    
    const dataMarkets = fundamentalsArr.flat();

    const renderMarketsData = (data) => {
      const dataCountry = { ...data };
      delete dataCountry.tableData;
      delete dataCountry.hour;
      delete dataCountry.variable;
      delete dataCountry.index;
      delete dataCountry.variableForLink;
      delete dataCountry.variableName;
      delete dataCountry.id;

      return (
        <TableRow key={`row_${data.tableData.uuid}`}>
          {data.variable && (
            <TableCell
              key={`var_${data.tableData.uuid}_${data.variable}}`}
              className={`${classes.tableCell} ${classes.stickyCol} ${
                data.index % 2 === 0 ? '' : classes.lightblueCell
              }`}
              rowSpan={Object.keys(fundamentals[data.variableForLink]).length}
              align="center"
            >
              {data.variable}
            </TableCell>
          )}
          <TableCell
            key={`hour_${data.tableData.uuid}_${data.hour}}`}
            align="center"
            className={`${classes.tableCell} ${classes.stickyCol} ${
              classes.updateHourCell
            } ${data.index % 2 === 0 ? '' : classes.lightblueCell}`}
          >
            {data.hour}
          </TableCell>
          {Object.keys(dataCountry).map((market, market_key) => {
            const issueTsArray = [];
            if (data.hour === allIssueTimestampsName && fundamentals[data.variableForLink]) {
              Object.keys(fundamentals[data.variableForLink]).forEach((issueTs) => {
                if(issueTs && issueTs !== allIssueTimestampsName){
                  if (fundamentals[data.variableForLink][issueTs][market])
                    issueTsArray.push(issueTs);
                }
              })
            }
            else {
              issueTsArray.push(data.hour);
            }
            return (
              <TableCell
                key={`mk_${market_key}_${data.tableData.uuid}`}
                align="center"
                className={`${classes.fundamentalCell} ${
                  market_key % 2 === 0
                    ? classes.grayCell
                    : data.index % 2 === 0
                    ? ''
                    : classes.lightblueCell
                } `}
              >
                <CustomTooltip
                  title="Go to details"
                  disableFocusListener={!helpModeActive}
                  disableHoverListener={!helpModeActive}
                  disableTouchListener={!helpModeActive}
                >
                  <Link
                    to={{
                      pathname: '/fundamentalsaudit',
                      state: {
                        country: market,
                        variable: data.variableForLink,
                        variableName : data.variableName,
                        issue_ts: issueTsArray,
                      },
                    }}
                  >
                    {data[market]}
                  </Link>
                </CustomTooltip>
              </TableCell>
            );
          })}
        </TableRow>
      );
    };

    return (
      <MaterialTable
        columns={columns}
        data={dataMarkets}
        options={{
          toolbar: false,
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: winHeight - 180,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
        }}
        components={{
          Row: ({ data }) => renderMarketsData(data),
        }}
      />
    );
  }
}

FundamentalsVariables.defaultProps = {
  tableHeaderColor: 'primary',
};

FundamentalsVariables.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(FundamentalsVariables);

