import Button from 'components/CustomButtons/Button';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

const DownloadButton = ({ data, tables, filename, tooltip, ...props }) => {
  const downloadLink = useRef();

  const processTableBeforeExport = (html) => {
    const processedHtml = html.cloneNode(true);
    // 1. Process dates
    let cells = processedHtml.querySelectorAll('tbody > tr >td:first-child');
    cells.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      if(item.textContent.split(' ').length > 2) item.textContent = data[index][0].replaceAll('-', '/');
      // eslint-enable
    });

    // 2. Remove "Actions" column from <table/>
    const lastCol = processedHtml.querySelector('thead > tr > th:last-child');
    if (lastCol.textContent === 'Actions') {
      lastCol.remove();
      const cols = processedHtml.querySelectorAll('tbody > tr');
      cols.forEach((item) => (
        item.querySelector('td:last-child').remove()
      ));
    }

    // 3. Remove spaces in numbers
    cells = processedHtml.querySelectorAll('tbody > tr > td');
    cells.forEach((item, index) => {
      // eslint-disable-next-line no-param-reassign
      if(!isNaN(item.textContent.split(' ')[0] - 1)) item.textContent = item.textContent.replaceAll(' ', '');
      // eslint-enable
    });
    return processedHtml.outerHTML;
  };

  const exportToExcel = () => {
    const divider = '<table><tr></tr><tr></tr><tr></tr><tr></tr></table>';

    let processedTables = '';
    [...tables].forEach((table) => {
      processedTables += `${processTableBeforeExport(table)}${divider}`;
    });
    const csvData = `data:application/csv;charset=utf-8,${
      encodeURIComponent(processedTables)}`;
    downloadLink.current.setAttribute('href', csvData);
    downloadLink.current.click();
  };

  /* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */
  return (
    <>
      <Button
        color="primary"
        round
        disabled={!(data?.length > 0)}
        onClick={exportToExcel}
        {...props}
        tooltip={tooltip}
      >
        Download
      </Button>
      <a
        id="id-plreport-toexcel"
        role="button"
        ref={downloadLink}
        target="_blank"
        download={`${filename}.xls`}
      />
    </>
  );
  /* eslint-enable */
};

DownloadButton.propTypes = {
  data: PropTypes.array,
  tables: PropTypes.object.isRequired,
  filename: PropTypes.string,
};

DownloadButton.defaultProps = {
  data: null,
  filename: 'output',
};

export default DownloadButton;
