import React, { Component } from 'react';

import MaterialTable from '@material-table/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

import { Input, HelpOutline } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';

import CustomTooltip from '../CustomTooltip/CustomTooltip.jsx';
import tableStyle from '../../assets/jss/material-dashboard-react/components/tableStyle.jsx';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

class Markets extends Component {
  componentDidMount() {}

  constructor() {
    super();
    this.gotoVariables = this.gotoVariables.bind(this);
  }

  gotoVariables() {
    this.props.history.push('/market_variables');
  }

  render() {
    const markets = this.props.markets;

    const { classes, winHeight, helpModeActive } = this.props;

    const headerStyle = {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      fontSize: '1em',
      padding: '12px 0',
      textAlign: 'center',
      color: primaryColor,
      fontWeight: 500,
    };

    const columns = [
      {
        title: 'ID',
        field: 'id',
        width: 70,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 0,
          zIndex: 1,
        },
        headerStyle: {
          left: '0px',
          zIndex: 3,
        },
      },
      {
        title: 'Name',
        field: 'name',
        width: 150,
        sorting: false,
        cellStyle: {
          backgroundColor: 'white',
          position: 'sticky',
          left: 70,
          zIndex: 1,
        },
        headerStyle: {
          left: '70px',
          zIndex: 3,
        },
      },
      {
        title: 'Description',
        field: 'description',
        width: 150,
        sorting: false,
      },
      {
        title: 'Country',
        field: 'country',
        width: 150,
        sorting: false,
      },
      {
        title: 'TimeZone',
        field: 'timeZone',
        width: 150,
        sorting: false,
      },
      {
        title: (
          <div className={classes.flexContainer}>
            <span>{'Market Data'}</span>
            {helpModeActive && (
              <CustomTooltip title='A button to navigate to the Market Data page for more details.'>
                <HelpOutline fontSize='small'/>
              </CustomTooltip>
            )}
          </div>
        ),
        field: 'actions',
        width: 100,
        sorting: false,
      },
    ];

    const marketsData = markets.map((market) => {
      const { name, description, id, tz_long, country_iso3166a2 } = market;
      return { name, description, id, tz_long, country_iso3166a2 };
    });

    const renderData = (data) => {
      const dataCountry = { ...data };
      delete dataCountry.tableData;
      return (
        <TableRow>
          <TableCell className={`${classes.stickyCol}`} align="center">
            {data.id}
          </TableCell>
          <TableCell className={`${classes.stickyCol} ${classes.nameCell}`}>
            {data.name}
          </TableCell>
          <TableCell align="center">{data.description}</TableCell>
          <TableCell align="center">{data.tz_long}</TableCell>
          <TableCell align="center">{data.country_iso3166a2}</TableCell>
          <TableCell align="center">
            <CustomTooltip 
              title="Go to details"
              disableFocusListener={!helpModeActive}
              disableHoverListener={!helpModeActive}
              disableTouchListener={!helpModeActive}
            >
              <Link
                to={{
                  pathname: '/market_variables',
                  state: {
                    data: markets.filter((i) => i.id === data.id)[0],
                  },
                }}
              >
                <Input />
              </Link>
            </CustomTooltip>
          </TableCell>
        </TableRow>
      );
    };

    return (
      <MaterialTable
        columns={columns}
        data={marketsData}
        sor
        options={{
          toolbar: false,
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: winHeight - 200,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
        }}
        components={{
          Row: ({ data }) => renderData(data),
        }}
      />
    );
  }
}

Markets.defaultProps = {
  tableHeaderColor: 'primary',
};

Markets.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(Markets);
