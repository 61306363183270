import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  lightroseColor,
  lightgrayColor,
  lightblueColor,
  defaultFont
} from "assets/jss/material-dashboard-react.jsx";

const tableStyle = theme => ({
  warningTableHeader: {
    color: warningColor
  },
  primaryTableHeader: {
    color: primaryColor,
  },
  primaryPnlTableHeader: {
    color: primaryColor,
    //gray border in the left and right of the header cell
    border: "0.5px solid gray",
  },
  borderedRoundHeader: {
    //gray border in the left and right of the header cell
    border: "0.5px solid gray",
  },
  borderedTopHeader: {
    // gray border only on the top of the cell
    borderTop: "0.5px solid gray",
  },
  borderedLeftHeader: {
    // gray border only on the right of the cell
    borderLeft: "0.5px solid gray",
  },
  borderedRightHeader: {
    // gray border only on the right of the cell
    borderRight: "0.5px solid gray",
  },
  dangerTableHeader: {
    color: dangerColor
  },
  successTableHeader: {
    color: successColor
  },
  infoTableHeader: {
    color: infoColor
  },
  roseTableHeader: {
    color: roseColor
  },
  grayTableHeader: {
    color: grayColor
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse"
  },
  tableHeadCell: {
    color: "inherit",
    ...defaultFont,
    fontSize: "1em"
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle"
  },
  tableResponsive: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  headerDataGrid: {
    "& .MuiDataGrid-columnHeaderTitle": {
      overflow: "visible",
      lineHeight: "1.43rem",
      whiteSpace: "normal",
      color: primaryColor,
      fontSize: "1em",
    }
  },
  exportBtn: {
    display: 'flex',
    marginLeft: '18px'
  },
  stickyCol: {
    position: "sticky",
    left: 0,
    background: "white",
    textAlign: "center",
  },
  stickyRowFirst: {
    position: "sticky",
    top: 0,
    background: "white",
    zIndex: 3,
    borderBottom: "none",
    height: 48,
    textAlign: "center",
  },
  stickyRowSecond: {
    position: "sticky",
    top: 80,
    background: "white",
    zIndex: 3,
    textAlign: "center",
  },
  topZero: {
    top: 0,
    zIndex : 5,
  },
  lightgreyCell: {
    backgroundColor: lightgrayColor,
  },
  grayCell: {
    backgroundColor: lightgrayColor
  },
  redCell: {
    backgroundColor: lightroseColor
  },
  lightblueCell: {
    backgroundColor: lightblueColor
  },
  sourceCell: {
    left: '100px'
  },
  variableCell: {
    left: '50px',
  },
  updateHourCell: {
    left: '150px'
  },
  nameCell: {
    left: '70px',
    textAlign: "center",
  },
  boldCell: {
    fontWeight: 'bold'
  },
  fundamentalCell: {
    padding: "1px 0",
    textAlign: 'center',
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  flexContainer: {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    gap: '5px',
  }

});

export default tableStyle;
