import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Button, Tooltip } from '@material-ui/core';

import CardHeader from "components/Card/CardHeader.jsx";
import { set_current_region } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

const mapStateToProps = state => {
  return { 
    curRegion: state.conn.curRegion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  set_current_region: (curRegion) => dispatch(set_current_region(curRegion)),
});

const RegionPicker = ({classes, markets, curRegion, set_current_region, helpModeActive}) => {
  const handleRegionClick = (e) => {
    set_current_region(e.target.innerText);
  }

  // Get list unique regions
  const uniqRegions = markets
  .filter((el) => el.parents.length)
  .map((el) => (el.parents[1] ? el.parents[1][1] : el.parents[0][1]))
  .filter((value, index, array) => array.indexOf(value) === index)
  .sort()
  .reverse();
  uniqRegions.length && uniqRegions.unshift('All');

  // Create buttons for regions
  const uniqRegionsButtons = uniqRegions.map((region, id) => {
    return (
      <Grid item key={id}>
        <CustomTooltip
          title='Filter markets by region'
          disableFocusListener={!helpModeActive}
          disableHoverListener={!helpModeActive}
          disableTouchListener={!helpModeActive}
        >
          <Button
            className={`${classes.cardTitleWhite} ${curRegion === region.toUpperCase() ? classes.activeTitle : ''}`}
            onClick={handleRegionClick}
          >
            {region}
          </Button>
        </CustomTooltip>
      </Grid>
    );
  });

  return (
    <CardHeader color="primary">
      <Grid container spacing={2}>
        {uniqRegionsButtons}
      </Grid>
    </CardHeader>
  )
}

const ConnectedPicker = connect(mapStateToProps, mapDispatchToProps)(RegionPicker);
export default withStyles(dashboardStyle)(ConnectedPicker);
