import React, { useRef, useState } from 'react';
import { GoogleMap, LoadScript, Marker,InfoWindow } from '@react-google-maps/api';

const CustomMap = ({markers}) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);

  const capitals = {
    'Germany': { lat: 52.5200, lng: 13.4050 },
    'Norway': { lat: 59.9139, lng: 10.7522 },
    'France': { lat: 48.8566, lng: 2.3522 },
    'Japan': { lat: 35.6895, lng: 139.6917 },
    'Poland': { lat: 52.2297, lng: 21.0122 },
    'United Kingdom': { lat: 51.5074, lng: -0.1278 },
    'Italy': { lat: 41.9028, lng: 12.4964 },
    'Belgium': { lat: 50.8503, lng: 4.3517 },
    'Romania': { lat: 44.4268, lng: 26.1025 },
  }

  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.setCenter({ lat: 52.5200, lng: 13.4050 });
  };

  return (
    <LoadScript googleMapsApiKey={process.env.MAP_API_KEY}>
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={{ width: "100%", height: "500px" }}
        zoom={5}
        options={{
          scrollwheel: false,
        }}
      >
        {markers && Object.keys(markers).map(type => {
          if (type === 'Flat Volume') {
            return Object.keys(markers[type]).map(country => {
              return (
                <Marker
                  key={country}
                  position={capitals[country]}
                  onMouseOver={() => setSelectedMarker({...markers[type][country], name: country})}
                  onMouseOut={() => setSelectedMarker(null)}
                >
                  {selectedMarker?.name === country && (
                    <InfoWindow 
                      position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                      options={{
                        disableAutoPan: true
                      }}
                    >
                      <div style={{position: 'relative', fontSize: '16px'}}>
                        <style>
                          {/* disabling close icon */}
                          {`
                            .gm-ui-hover-effect {
                              display: none !important;
                            }
                          `}
                        </style>
                        {`${selectedMarker.name}: ${selectedMarker.totalVolume} MW`}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              )
            })
          } else {
            return Object.entries(markers[type]).map(([country, countryData]) => {
              return Object.entries(countryData).map(([city, data]) => {
                return (
                  <Marker
                    key={country + city}
                    position={{
                      lat: data.lat + 0.001,
                      lng: data.lng
                    }}
                    onMouseOver={() => setSelectedMarker({...markers[type][country][city], name: `${country}, ${city}`})}
                    onMouseOut={() => setSelectedMarker(null)}
                  >
                    {selectedMarker?.name === `${country}, ${city}` && (
                      <InfoWindow 
                        position={{lat: selectedMarker.lat, lng: selectedMarker.lng}}
                        options={{
                          disableAutoPan: true
                        }}
                      >
                        <div style={{position: 'relative', fontSize: '16px'}}>
                          <style>
                            {/* disabling close icon */}
                            {`
                              .gm-ui-hover-effect {
                                display: none !important;
                              }
                            `}
                          </style>
                          {selectedMarker.parks.map((park) => (
                            <div key={park.id} style={{textAlign: 'left'}}>{`${park.name}: ${park.tradedVolume} MW`}</div>
                          ))}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                )
              })
            })
          }
        })}
      </GoogleMap>
    </LoadScript>
  )
}

export default CustomMap;