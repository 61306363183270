import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

import MaterialTable from '@material-table/core';

import withStyles from '@material-ui/core/styles/withStyles';
import tableStyle from 'assets/jss/material-dashboard-react/components/tableStyle.jsx';
import { primaryColor } from 'assets/jss/material-dashboard-react';
import PropTypes from 'prop-types';

import LoginPage from 'views/Login/Oops';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const Variables = (props) => {

  const dataMarkets = [];
  // selected markets to display, add more if needed, and adjust the
  // markup below...
  const markets = props.marketsCountries.map((market) => {
    return { country: market.country_iso3166a2, name: market.name };
  });

  if (markets.length) {
    markets.sort((a, b) => (a.country > b.country ? 1 : -1));
    // Place DE and FR at the start
    const posDe = markets.findIndex((object) => object.country === 'DE');
    markets.splice(posDe, 1);
    const posFr = markets.findIndex((object) => object.country === 'FR');
    markets.splice(posFr, 1);
    markets.unshift({ country: 'FR', name: 'France' });
    markets.unshift({ country: 'DE', name: 'Germany' });
  }

  // Classification of the markets by region
  const marketsRegion = markets.map((market) => {
    const region = props.marketsCountries.find(
      (el) => el.name === market.name
    );
    return {
      name: market.name,
      country: market.country,
      region:
        region?.parents.length === 2
          ? region?.parents[1][1]
          : region?.parents[0][1],
    };
  });

  let dataSources = [{ high: [] }, { medium: [] }, { low: [] }];
  for (let country in props.markets) {
    for (let importance in props.markets[country]) {
      let found = false;
      let index = -1;
      for (let i = 0; i < dataSources.length; i++) {
        if (importance === Object.keys(dataSources[i])[0]) {
          found = true;
          index = i;
          break;
        }
      }
      if (found === false) {
        dataSources.push({ [importance]: [] });
        index = dataSources.length - 1;
      }
      for (let source in props.markets[country][importance]) {
        found = false;
        for (let i = 0; i < dataSources[index][importance].length; i++) {
          if (dataSources[index][importance][i] === source) {
            found = true;
            break;
          }
        }
        if (found === false) {
          dataSources[index][importance].push(source);
        }
      }
    }
  }
  const { classes } = props;

  const headerStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    color: primaryColor,
    fontSize: '1em',
    padding: '1em 0',
    textAlign: 'center',
  };

  const columns = [
    {
      title: 'Importance',
      field: 'importance',
      width: 100,
      sorting: false,
      cellStyle: {
        backgroundColor: 'white',
        position: 'sticky',
        left: 0,
        zIndex: 1,
      },
      headerStyle: {
        left: '0px',
        zIndex: 3,
      },
    },
    {
      title: 'Source',
      field: 'source',
      width: 150,
      sorting: false,
      cellStyle: {
        backgroundColor: 'white',
        position: 'sticky',
        left: 100,
        zIndex: 1,
      },
      headerStyle: {
        left: 100,
        zIndex: 3,
      },
    },
  ];

  // Only markets of the selected region
  const filterMarkets =
  props.activeRegion !== 'ALL'
    ? marketsRegion.filter(
        (market) => market.region.toUpperCase() === props.activeRegion
      )
    : marketsRegion;

  filterMarkets.forEach((market) => {
    columns.push({
      title: market.name,
      field: market.country,
      width: 80,
      sorting: false,
    });
  });


  dataSources.forEach((importance) =>
    importance[Object.keys(importance)[0]].forEach(
      (dataSource, datasource_key) => {
        const marketData = {};
        filterMarkets.forEach((market) => {
          if (
            props.markets[market.country] !== undefined &&
            props.markets[market.country][Object.keys(importance)[0]] !==
              undefined &&
            props.markets[market.country][Object.keys(importance)[0]][
              dataSource
            ] !== undefined
          ) {
            marketData[market.country] =
              props.markets[market.country][Object.keys(importance)[0]][
                dataSource
              ];
          } else {
            marketData[market.country] = '';
          }
        });

        dataMarkets.push({
          id: `${Object.keys(importance)[0]}_${datasource_key}`,
          importance: Object.keys(importance)[0],
          source: dataSource,
          source_key: datasource_key,
          ...marketData,
        });
      }
    )
  );

  const renderMarketsData = (data) => {
    const dataCountry = { ...data };
    delete dataCountry.tableData;
    delete dataCountry.source;
    delete dataCountry.importance;
    delete dataCountry.source_key;
    delete dataCountry.id;

    return (
      <TableRow hover key={`row_${data.tableData.id}`}>
        {(data.tableData.index === 0 ||
          dataMarkets[data.tableData.index]['importance'] !==
            dataMarkets[data.tableData.index - 1]['importance']) && (
          <TableCell
            key={Object.keys(importanceObj).indexOf(
              data.importance * 7777 + data.source_key + 1
            )}
            className={`${classes.tableCell} ${classes.stickyCol}`}
            rowSpan={importanceObj[data.importance]}
            align="center"
          >
            {data.importance}
          </TableCell>
        )}
        <TableCell
          key={data.source_key * 8888 + 6666}
          align="center"
          className={`${classes.tableCell} ${classes.stickyCol} ${classes.sourceCell}`}
        >
          {data.source}
        </TableCell>

        {Object.keys(dataCountry).map((market, market_key) => {
          const cellClasses = `
            ${market_key % 2 === 0 ? classes.grayCell : ''}
            ${data[market] !== '' && data[market] <= 50 ? classes.redCell : ''}
          `;
          return (
            <TableCell
              key={market_key + 555 * data.source_key}
              align="center"
              className={cellClasses}
            >
              <CustomTooltip
                title="Go to details"
                disableFocusListener={!props.helpModeActive}
                disableHoverListener={!props.helpModeActive}
                disableTouchListener={!props.helpModeActive}
              >
                <Link
                  to={{
                    pathname: '/marketaudit',
                    state: {
                      country: market,
                      source: data.source,
                      importance: data.importance,
                    },
                  }}
                >
                  {data[market]}
                </Link>
              </CustomTooltip>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const importanceObj = {};
  dataMarkets.forEach((item) => {
    if (importanceObj[item.importance] === undefined) {
      importanceObj[item.importance] = 0;
    }
    importanceObj[item.importance] += 1;
  });

  if (!props.isLoggedIn) return <LoginPage />;
  return (
    markets.length > 0 && (
      <MaterialTable
        columns={columns}
        data={dataMarkets}
        title={'Markets Dashboard Table'}
        options={{
          tableLayout: 'fixed',
          paging: false,
          maxBodyHeight: props.winHeight - 180,
          headerStyle: headerStyle,
          emptyRowsWhenPaging: false,
          pageSize: 6,
          draggable: false,
          search: false,
          toolbar: false,
        }}
        components={{
          Row: ({ data }) => renderMarketsData(data),
        }}
      />
    )
  );
};

Variables.defaultProps = {
  tableHeaderColor: 'primary',
};

Variables.propTypes = {
  classes: PropTypes.object.isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
};

export default withStyles(tableStyle)(Variables);

