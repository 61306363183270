import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import LoginPage from "views/Login/Oops.jsx";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Variables from 'components/MarketsDashboard/Variables';
import RegionPicker from "components/Table/RegionPicker";

import { get_marketsdata_data, get_markets_data } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const mapStateToProps = state => {
  return { 
    isLoggedIn: state.login.loggedIn, 
    loading: state.marketsdata.loading,
    markets: state.marketsdata.markets,
    marketsCountries: state.markets.markets,
    curRegion: state.conn.curRegion,
    };
};

const mapDispatchToProps = (dispatch) => ({
  get_marketsdata_data: data => dispatch(get_marketsdata_data(data)),
  getMarkets: () => dispatch(get_markets_data()),
});

class MarketsData extends React.Component {
  componentDidMount(){
    if (this.props.isLoggedIn) {
      this.props.get_marketsdata_data();
      this.props.getMarkets();
    }
  }

  render() {
    const { isLoggedIn, loading, markets, marketsCountries, curRegion, helpModeActive } = this.props;

    if (this.props?.isLoggedIn) {
      if (this.props.loading) {
        return (
          <div className="loader" alt="Loading report..."/>
        );
      }
    } else {
      return (<LoginPage />);
    }
    if (isLoggedIn) {
      return (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <RegionPicker markets={this.props.marketsCountries} helpModeActive={helpModeActive}/>
                {(marketsCountries && markets) ?
                  <CardBody >
                    <Variables
                      markets={this.props.markets}
                      winHeight={window.innerHeight}
                      isLoggedIn={this.props.isLoggedIn}
                      marketsCountries={this.props.marketsCountries.filter(el => el.parent_id)}
                      activeRegion={curRegion}
                      helpModeActive={helpModeActive}
                    />
                  </CardBody> : loading ?
                    <div className="loader" alt="Loading report..."/>
                    : null
                }
                <CardFooter xs={12} sm={12} md={12}>
                </CardFooter>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
  }
}

MarketsData.propTypes = {
  classes: PropTypes.object.isRequired
};

const MarketsDataConnect = connect(mapStateToProps, mapDispatchToProps)(MarketsData);
export default withStyles(dashboardStyle)(MarketsDataConnect);


