import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from '@material-ui/core';
import Markets from 'components/Markets/Markets';
import RegionPicker from "components/Table/RegionPicker";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import LoginPage from "views/Login/Oops.jsx";
import { get_markets_data } from "actions/index";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const mapStateToProps = state => {
  return { 
    markets: state.markets.markets,
    loading: state.markets.loading, 
    isLoggedIn: state.login.loggedIn,
    curRegion: state.conn.curRegion,
  };
};

const mapDispatchToProps = (dispatch) => ({
  get_markets_data: data => dispatch(get_markets_data(data)),
});

class MarketsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  componentDidMount(){
    this.props.get_markets_data();
  }

  render() {
    const { markets, curRegion } = this.props;
    const tableHead = ['ID', 'Name', 'Description', 'Country', 'TimeZone', 'Actions'];

    const filteredMarkets = markets.length 
      ? markets.filter(el => {
        if (curRegion === 'ALL' || 
          el.name.toLowerCase() === curRegion.toLowerCase() || 
          (el.parents.length === 2 && el.parents[1][1].toLowerCase() === curRegion.toLowerCase()) || 
          (el.parents.length && el.parents[0][1].toLowerCase() === curRegion.toLowerCase())
        ) {
          return true
        } else return false
      })
      : []
    
    let tableData = filteredMarkets;
    // Sort Markets by alphabetical order
    tableData.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    // Put "Germany" and "France" on top 2 positions
    const de = tableData.filter((x) => x.name.includes('Germany'));
    const fr = tableData.filter((x) => x.name.includes('France'));
    tableData = tableData.filter((x) => (
      ((de.length && x.id !== de[0].id) && (fr.length && x.id !== fr[0].id))
    ));
    tableData = [ ...de, ...fr, ...tableData ];
    tableData = tableData.map(
      (x) => [x.id, x.name, x.description, x.country_iso3166a2, x.tz_long, 'Edit'],
    );
    if (this.props?.isLoggedIn) {
      return (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card>
              <RegionPicker markets={markets} helpModeActive={this.props.helpModeActive}/>
              {this.props.markets?.length > 0
                ? (
                  <CardBody>
                    <Markets
                      tableHead={tableHead}
                      tableData={tableData}
                      markets={filteredMarkets}
                      winHeight={window.innerHeight}
                      helpModeActive={this.props.helpModeActive}
                    />
                  </CardBody>
                )
                : (this.props.loading)
                  ? (<div className="loader" alt="Loading report..."/>)
                  : null
              }
            </Card>
          </Grid>
        </Grid>
      );
    } else {
      return (<LoginPage />);
    }
  }
}

const ConnectedMarkets = connect(mapStateToProps, mapDispatchToProps)(MarketsList);
export default withStyles(dashboardStyle)(ConnectedMarkets);
